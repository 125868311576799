@font-face {
  font-family: 'Space Grotesk';
  src: url('fonts/SpaceGrotesk/woff2/SpaceGrotesk-VariableFont_wght.woff2') format('woff2'),
    url('fonts/SpaceGrotesk/ttf/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-variation-settings: 'wght' 300, 'wght' 400, 'wght' 500, 'wght' 600, 'wght' 700;
  unicode-range: U+0000 - U+007F, U+2000 - U+206F;
}

@font-face {
  font-family: 'Work Sans';
  src: url('fonts/WorkSans/woff2/WorkSans-VariableFont_wght.woff2') format('woff2'),
    url('fonts/WorkSans/ttf/WorkSans-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-variation-settings: 'wght' 400, 'wght' 600;
  unicode-range: U+0000 - U+007F, U+2000 - U+206F;
}
