.cases-list {
  margin-top: 60px;
  display: grid;
  grid-gap: 20px;


  @include respond-to('phone') {
    grid-template-columns: 1fr;
  }


  &__element {
    display: grid;
    grid-gap: 20px;
    margin-bottom: 60px;

    & > div {
      @include respond-to('phone') {
        margin-bottom: 50px;
      }
    }


    &:nth-child(odd) {
      grid-template-columns: 7fr 5fr;

      @include respond-to('phone') {
        grid-template-columns: 1fr;
      }
    }

    &:nth-child(even) {
      grid-template-columns: 5fr 7fr;

       @include respond-to('phone') {
        grid-template-columns: 1fr;
      }
    }


    @include respond-to('phone') {
      margin-bottom: 0;
      grid-gap: 0;
    }

    width: 100%;

    .case-study-image {
      width: 100%;
      display: grid;
      justify-content: normal;
    }

    &__tags {
      width: 100%;
      margin-top: 34px;
      margin-bottom: 40px;

      &__one {
        margin-right: 10px;
        width: fit-content;
        display: inline-block;
      }

      .padding-case {
        padding: 5px;
        color: #0b0a0e;
      }
    }

    &__title {
      width: 100%;
      display: grid;
    }
  }

  a {
    text-decoration: none !important;
  }
}


.case24-hero {
  margin-top: 107px;

  &__image {
    width: 100%;
    padding-bottom: 100px;

    @include respond-to('phone') {
      padding-bottom: 10px;
    }

    picture {
      width: 100%;
      height: 100%;
      display: grid;
    }

    img {
      width: 100%;
      max-height: 381px;
      object-fit: cover;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: auto auto;
    padding: 104px 0;
    gap: 32px;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
      padding: 10px 0;
    }

    &__title {
      &--small {
        width: 50%;

        @include respond-to('phone') {
          width: 100%;
        }
      }

      &__tags {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 40px;
        width: fit-content;


        &__tag {
          background: #efff04;
          padding: 0 10px;
        }
      }

      p {
        padding-top: 40px;
      }
    }

    &__info {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 32px;

      @include respond-to('phone') {
        margin-top: 60px;
        grid-template-columns: 1fr;
      }

      &__box {
        &__label {
          margin-bottom: 20px;
        }

        &__item {
          width: 100%;
          display: block;
        }
      }
    }
  }

}

.case24-table {
  position: absolute;
  width: 100%;
  top: 488px;
  padding: 32px 0;
  height: 100px;
  border-bottom: 1px solid $grey-dark;
  z-index: 99;

  @include respond-to('phone') {
    display: none;
  }

  &__list {
    display: block;

    &__item {
      display: inline-block;
      margin-right: 30px;
      cursor: pointer;
      padding-top: 13px;
    }
  }

}


.container-title {
  max-width: calc(100% - 200px);
  margin: auto;

  @include respond-to('phone') {
    max-width: 100%;
    padding: 16px;
  }
}


.container-widget {
  max-width: calc(1422px + 208px);
  scroll-margin: 107px;
  margin: 0 104px;

  @include respond-to('phone') {
    max-width: 100%;
    padding: 16px;
    margin: 0;
  }
}

.case24-paragraph {
  display: grid;
  grid-template-columns: 2fr 3fr;
  position: relative;

  @include respond-to('phone') {
    grid-template-columns: 1fr;
  }

  &--padding {
    padding-bottom: 104px;

    @include respond-to('phone') {
      padding-bottom: 0;
    }
  }

  &__title {
    position: relative;
    height: 100%;

    h2 {
      position: sticky;
      top: 167px;

      @include respond-to('phone') {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 300;
        letter-spacing: -0.8px;
        font-size: 26px;
        line-height: 110%;
        margin-bottom: 32px;

      }

    }
  }

  &__wyswig {
    p {
      margin-bottom: 32px;
    }
  }
}


.case24-scope {
  display: grid;
  grid-template-columns: 2fr 3fr;
  padding-bottom: 136px;

  @include respond-to('phone') {
    grid-template-columns: 1fr;
    padding-bottom: 60px;
  }

  &__image {
    width: 75%;

    @include respond-to('phone') {
      width: 100%;
    }

    picture, img {
      width: 100%;
    }
  }

  &__content {
    &__caption {
      @include respond-to('phone') {
        margin-top: 32px;
      }
    }

    &__title {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    &__list {
      &__item {
        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $grey-dark;
      }
    }
  }
}

.case24-image-video {
  padding: 104px 0;
  width: 100%;

  @include respond-to('phone') {
    padding: 32px 0;
  }

  &__video {
    width: 100%;

    video {
      width: 100%;
    }
  }

  &__image {
    width: 100%;

    picture, img {
      width: 100%;
    }
  }
}

.case24-masonry {
  columns: 50% 2;
  column-gap: 32px;
  width: 100%;
  padding-bottom: 104px;

  @include respond-to('phone') {
    padding-bottom: 60px;
    columns: 100% 1;
    column-gap: 0;
  }

  &__item {
    margin-bottom: 32px;
    width: 100%;
    height: 100%;
    display: block;

    video {
      width: 100%;
      height: 100%;
      display: block;
    }

    picture, img {
      width: 100%;
      height: 100%;
      display: block;
    }


    /* Re-order items into 3 rows */
    &:nth-child(odd) {
      order: 1;
    }

    &:nth-child(even) {
      order: 2;
    }
  }
}

.case24-statistic {
  padding-bottom: 104px;
  display: grid;
  grid-template-columns: 2fr 3fr;

  @include respond-to('phone') {
    grid-template-columns: 1fr;
  }


  &__caption {
    height: 100%;

    > h2 {
      position: sticky;
      top: 107px;

      @include respond-to('phone') {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 300;
        letter-spacing: -0.8px;
        font-size: 26px;
        line-height: 110%;
        margin-bottom: 32px;

      }
    }
  }

  &__content {
    &__paragraph {
      margin-bottom: 32px;
    }

    &__number {
      padding-bottom: 32px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px solid $grey-dark;

      @include respond-to('phone') {
        grid-template-columns: 1fr;
      }

      &__big {

      }

      &__text {
        margin-left: 30%;
        width: 70%;
        display: flex;
        align-items: center;

        @include respond-to('phone') {
          margin-left: 0;
          margin-top: 24px;
        }
      }
    }
  }
}
