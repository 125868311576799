.service-list {
  padding-top: 20px;
  padding-bottom: 80px;

  @include respond-to('fullhd') {
    padding-top: 40px;
  }

  @include respond-to('phone') {
    padding-top: 0;
    padding-bottom: 50px;
  }

  @include respond-to('tablet') {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .service-list__element {
    display: inline-block;
    vertical-align: top;
    width: calc(33% - 68px);
    margin-right: 100px;

    &__mask {
      position: relative;
      height: 100%;
      width: 100%;
      padding-top: 56.25%;
      margin-bottom: 25px;
      overflow: hidden;

      @include respond-to('phone') {
        display: none;
      }

      &__video {
        width: 100%;
        position: absolute;
        top: 0;

        @include respond-to('phone') {
          display: none;
        }
      }
    }

    &__img img {
      width: 100%;
      object-fit: cover;
      margin-bottom: 25px;
      height: 100%;

      @include respond-to('phone') {
        display: none;
      }
    }

    &__mobile-image {
      display: none;

      @include respond-to('phone') {
        display: block;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
          margin-bottom: 25px;
        }
      }
    }

    @include respond-to('phone') {
      width: 100%;
      margin-right: 0;
      margin-top: 40px;
    }

    @include respond-to('tablet') {
      width: calc(50% - 30px);
      margin-right: 50px;
      margin-bottom: 50px;
    }

    &:nth-child(3n) {
      margin-right: 0;

      @include respond-to('tablet') {
        margin-right: 50px;
      }
    }

    &:nth-child(2n) {
      @include respond-to('tablet') {
        margin-right: 0;
      }
    }

    &:nth-child(n + 4) {
      margin-top: 60px;

      @include respond-to('phone') {
        padding: 0;
      }

      @include respond-to('tablet') {
        margin-top: 0;
      }
    }

    &__left {
      display: inline-block;
      vertical-align: top;
      width: 100%;

      &--topic {
        @extend .typography--h3;

        //height: 60px;
        transition: 0.4s all;

        @include respond-to('fullhd') {
          font-size: 30px;
          line-height: 40px;
        }

        //&:hover {
        //  color: #EFFF04 !important;
        //}

        &::before {
          content: attr(data-before);
          word-break: break-word;
          opacity: 0.5;
        }

        &::after {
          content: attr(data-after);
          word-break: break-word;
          opacity: 0.5;
        }
      }
    }

    &__right {
      &__text {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: #f0edeb;

        ul {
          margin-bottom: 20px;

          li {
            &::before {
              content: '';
              display: inline-block;
              height: 1em;
              width: 1em;
              background: url('img/arrow-services.svg') no-repeat 0 0;
              list-style-type: none;
              margin: 0;
              padding: 0 0 0 25px;
              vertical-align: middle;
            }
          }
        }
      }

      &--description {
        color: #27292a;
        height: fit-content;
        margin-top: 18px;

        @extend .typography--bodyLargeRegular;

        margin-bottom: 27px;

        @include respond-to('phone') {
          height: auto;
        }

        @include respond-to('tablet') {
          height: auto;
        }

        @include respond-to('fullhd') {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
}

.animate-labels {
  .container {
    padding: 0 150px !important;
    width: auto !important;
    margin: auto !important;

    @include respond-to('fullhd') {
      padding: 0 104px !important;
    }

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }
}

.blog-widget {
  .container {
    padding: 0 150px !important;
    margin: auto !important;
    width: auto !important;

    @include respond-to('fullhd') {
      padding: 0 104px !important;
      width: auto !important;
    }

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }
}
