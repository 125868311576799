.footer-article {
  background-color: #13101b;
  padding-top: 90px;
  padding-bottom: 40px;

  @include respond-to('phone') {
    padding-bottom: 20px;
    padding-top: 45px;
  }

  &__left {
    display: inline-grid;
    width: calc(33% - 45px);
    margin-right: 45px;

    @include respond-to('phone') {
      display: block;
      width: 100%;
      margin-right: 0;
    }

    h2 {
      font-family: 'Space Grotesk', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 52px;
      letter-spacing: -0.04em;
      font-feature-settings: 'dnom' on, 'numr' on, 'sinf' on, 'case' on, 'zero' on, 'ss04' on, 'ss05' on, 'ss03' on,
        'ss01' on, 'salt' on, 'ordn' on;
      color: #fff;
      margin-bottom: 40px;

      @include respond-to('phone') {
        margin-bottom: 20px;
      }
    }

    p {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.05em;
      color: #979797;
      margin-bottom: 40px;

      @include respond-to('phone') {
        margin-bottom: 20px;
      }
    }
  }

  &__right {
    display: inline-grid;
    width: calc(66% - 45px);
    margin-left: 45px;

    @include respond-to('phone') {
      display: block;
      width: 100%;
      margin-left: 0;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 90px;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
      width: 100%;
      column-gap: 0;
      margin-top: 46px;
    }

    &__box {
      margin-bottom: 66px;

      &__tag {
        background-color: #979797;
        padding: 5px 10px;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #12101a;
        margin-bottom: 19px;
        width: fit-content;

        @include respond-to('phone') {
          margin-bottom: 10px;
        }
      }

      h3 a {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.05em;
        color: #f0edeb;
      }
    }
  }
}
