.toggle {
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-gap: 136px;

  @include respond-to('phone') {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  &--padding {
    padding-top: 104px;


    @include respond-to('phone') {
      padding-top: 54px;
      padding-bottom: 54px;
    }
  }

  &__image {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__right {
    display: grid;
    align-content: center;

    @include respond-to('phone') {
    }

    h2 {
      margin-bottom: 48px;
    }

    p {
      margin-bottom: 8px;
    }


    &__list {
      &__li {
        height: 0;
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease-in-out;

        &--active {
          height: auto;
          opacity: 1;
          visibility: visible;
          padding-top: 16px;
        }
      }

      li {
        border-top: 1px solid $grey-dark;
        padding-top: 16px;
        padding-bottom: 16px;
        cursor: pointer;


        h2, h3 {
          cursor: pointer;
          position: relative;
          width: 85%;

          @include respond-to('phone') {
            width: 85%;
          }

          &::after {
            content: " ";
            position: absolute;
            top: 6px;
            width: 24px;
            right: -15%;
            height: 24px;
            background-image: url("img/arrow-down-skew.svg");
            background-repeat: no-repeat;

            @include respond-to('phone') {
              top: 1px;
            }
          }

          &::before {
            content: ' ';
            position: absolute;
            width: 24px;
            height: 24px;
            right: -15%;
            top: 6px;
          }
        }

        a {
          color: #fff;
        }
      }

      &__link {
        width: fit-content !important;
        height: fit-content !important;
        margin-top: 24px !important;
      }

      &__divlink {
        width: 100%;
        text-align: right;
        display: grid;
        justify-content: end;

        a {
          color: #0b0a0e !important;
        }
      }
    }
  }
}

.no-after {
  &::after {
    transition: 0.2s;
    transform: rotate(-45deg);
    background-image: url("img/arrow-down-skew-black.svg") !important;
  }

   &::before {
    background-color: #efff04;
  }
}

.toggle-new {
  display: block;


  &__body {
    margin-top: 48px;
    width: 100%;


    &__box {
      padding: 32px 0;
      border-top: 1px solid #353538;
      display: grid;
      width: 100%;
      grid-template-columns: 3fr minmax(0, 1fr) 4fr 1fr;
      position: relative;


      @include respond-to('phone') {
        grid-template-columns: 1fr;
      }

      &:hover {
        .toggle-new__body__box__text__hover, .toggle-new__body__box__text__hover p, .toggle-new__body__box__img {
          max-height: 500px;
          transition: max-height 0.5s ease-in;

        }

        .button-toggle {
          &::after {
            background: url("img/arrow-down-skew-black.svg");
            transform: rotate(-45deg);
            background-repeat: no-repeat;
            background-position: center center;
            z-index: 2;
          }

          &__nolink {
            &::before {
              right: 0 !important;
            }

          }

          &::before {
            background-color: #efff04;
            content: " ";
            width: 24px;
            position: absolute;
            height: 24px;
            padding: 8px;
            right: -40px;
            z-index: 1;
            top: -2px;

          }
        }
      }

      > div {
        display: grid;
        margin-right: 32px;

        @include respond-to('phone') {
          margin-right: 0;
        }
      }

      &__img {
        width: 100%;
        align-items: center;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s ease-out;


        img {
          width: 100%;
          display: grid;
          align-items: center;
          transition: all 0.4s;

          @include respond-to('phone') {
            margin-bottom: 32px;
          }
        }
      }

      &__text {
        h3 {
          width: 62%;
        }

        &__hover {
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.5s ease-out;


          & > div {
            margin-top: 32px;
            margin-bottom: 32px;

          }
        }

        &__tag {
          border: 1px solid #353538;
          background: #353538;
          display: inline-block;
          padding: 8px;
          margin-right: 16px;
          margin-top: 16px;
        }

      }

      .button-toggle {
        text-transform: inherit !important;
        position: relative;

        @include respond-to('phone') {
          position: absolute;
          right: 55px;
          bottom: 34px;
        }

        &::after {
          content: " ";
          width: 24px;
          position: absolute;
          height: 24px;
          padding: 8px;
          transition: all 0.4s ease;
          background: url("img/arrow-down-skew.svg");
          background-repeat: no-repeat;
          background-position: center center;
          margin-left: 16px;
          top: -2px;
        }

        &__nolink {
          &::after {
            right: 0;
          }
        }
      }
    }
  }
}
