/* media query break */

$break-tablet: 767px;
$break-desktop: 1200px;
$break-hd: 1280px;
$break-full-hd: 1440px;
$phone-sm: 360px;
$phone-md: 420px;
$phone-lg: 480px;
$max-full: 1680px;
$font-family: 'Work Sans', sans-serif;
$og-black: #09080b;
$og-gray: #909090;
$og-dark-gray: #444;
$black-darker-color: #121212;
$primary-lighten-color: #a1a1a1;
$primary-color: #aaa;
$primary-darker-color: #777;
$brand-yellow: #efff04;
$surface-primary: #0b0a0e;
$og-green: #2ce2b5;
$grey-dark: #353538;
$text-primary: #f5f6f7;
$grey-medium: #aeb4bb;
$surface-invert: #f5f6f7;
$white: #fff;
