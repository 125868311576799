.details__body {
  &__content {
    max-width: 940px;
    margin: 0 auto;

    h2 {
      @extend .typography--h2
    }

    h3 {
      @extend .typography--h3
    }

    h4 {
      @extend .typography--h4
    }

    p {
      position: relative;

      @extend .typography--bodyLargeRegular;

      //margin: 30px auto;

      &:last-child {
        margin-bottom: 0;
      }

      @include respond-to('phone') {
        font-size: 18px;
        line-height: 28px;
      }
    }

    i {
      font-style: italic;
      font-size: 24px;
      line-height: 38px;
      background: $primary-lighten-color;
      color: #000 !important;

      @include respond-to('phone') {
        font-size: 18px;
        line-height: 28px;
      }
    }

    b {
      font-weight: 800;
    }

    a {
      font-weight: 600;
      letter-spacing: 0.5px;

      &:hover {
        text-decoration: underline;
      }

      &.file {
        position: relative;
        display: block;
        max-width: 470px;
        padding: 10px 10px 10px 34px;
        background: $primary-lighten-color;
        border: 1px solid $primary-color;
        border-radius: 6px;

        &::before {
          content: '';
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 14px;
          height: 14px;

          // TODO: wstawić odpowiednie tło
          //background: url("ordergroup/tdc_download.svg") center no-repeat;
        }
      }

      &.link {
      }
    }

    ul {
      //list-style-image: url('img/tick.svg') !important;
      //list-style-position: inside;
    }

    ol {
      list-style: decimal;
    }

    ul,
    ol {
      position: relative;
      margin-left: 20px;
      margin-top: 0;

      li {
        font-weight: 500;
        font-size: 18px;
        line-height: 38px;
        letter-spacing: 0.5px;
      }
    }

    img {
      display: block;
      border-radius: 3px;
      transition: 0.25s ease;

      &.left {
        margin-right: auto;
      }

      &.right {
        margin-left: auto;
      }

      &.full-width {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 300px);
        height: auto;
        object-fit: contain;

        @include respond-to('fullhd') {
          width: calc(100vw - 320px);
        }

        @include respond-to('tablet') {
          width: calc(100vw - 100px);
        }

        @include respond-to('phone') {
          position: static;
          left: 0;
          transform: translateX(0);
          width: 100%;
        }
      }

      @include respond-to('phone') {
        width: 100%;
        height: 100%;
      }
    }

    @include respond-to('phone') {
      h2,
      h3,
      h4,
      p {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .rich-text > *:first-child {
      margin-top: 0;
    }
  }
}
