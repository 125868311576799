.four_columns {
  &__container {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 42px;

    @include respond-to('phone') {
      gap: 16px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__box {
    padding: 20px;
    position: relative;
    padding-right: 42px;
    border-right: 1px solid rgb(245 246 247 / 18%);
    height: 360px;
    box-sizing: border-box;
    margin-bottom: 104px;

    @include respond-to('phone') {
      margin-bottom: 32px;
      padding: 12px;
      padding-right: 12px;
    }


    &:nth-child(4n) {
      margin-bottom: 0;
    }

    &:nth-child(4n + 1) {
      border-left: 1px solid rgb(245 246 247 / 18%);

      @include respond-to('phone') {
        border-left: none;
      }
    }

    &:nth-child(2n) {
      @include respond-to('phone') {
        border-right: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: self-start;

      &__icon {
        width: 55px;
        height: 55px;
        margin-bottom: 32px;
      }

      h3 {
        margin-bottom: 32px;
      }

      p {
        text-align: left;
      }

    }
  }
}
