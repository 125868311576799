.typography {
  // VARIANTS
  &--h1, h1 {
    font-family: 'Work Sans', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.96px;

    @include respond-to('phone') {
      font-size: 32px;
      line-height: 110%;
    }
  }

  &--h2, h2 {
    font-family: 'Work Sans', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.8px;

    @include respond-to('phone') {
      font-size: 26px;
      line-height: 110%;
    }
  }

  &--h3, h3 {
    font-family: 'Work Sans', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.56px;

    @include respond-to('phone') {
      font-size: 22px;
      line-height: 110%;
    }

  }

  &--h4, h4 {
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.24px;

    @include respond-to('phone') {
      font-size: 18px;
      line-height: 110%;
    }
  }

  &--titleLarge {
    font-family: 'Work Sans', sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: 112%;
    letter-spacing: -0.96px;

    @include respond-to('phone') {
      font-size: 28px;
      line-height: 110%;
    }
  }

  &--titleMedium {
    font-family: "Work Sans", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.72px;

     @include respond-to('phone') {
      font-size: 22px;
      line-height: 110%;
    }
  }

  &--disclaimer {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    @include respond-to('phone') {
      font-size: 10px;
      line-height: 110%;
    }
  }

  &--display {
    font-family: "Space Grotesk", sans-serif;
    font-size: 120px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 144px */
    letter-spacing: -2.4px;

    @include respond-to('phone') {
      font-size: 80px;
    }
  }

  &--caption {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.8px;
    text-transform: uppercase;

    @include respond-to('phone') {
      font-size: 14px;
    }
  }

  &--link {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-decoration-line: underline;

  }

  &--bodyLargeMedium {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.4px;

    @include respond-to('phone') {
      font-size: 16px;
      line-height: 140%;
    }
  }

  &--bodyLargeRegular, &--bodyLargeRegular p {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: -0.4px;

    @include respond-to('phone') {
      font-size: 16px;
      line-height: 140%;
    }
  }

  &--bodyMediumMedium, &--bodyMediumMedium p {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @include respond-to('phone') {
      font-size: 15px;
    }
  }

  &--bodyMediumRegular, &--bodyMediumRegular p {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;

    @include respond-to('phone') {
      font-size: 15px;
    }
  }

  &--bodySmallRegular, &--bodySmallRegular p {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    letter-spacing: -0.28px;

  }

  &--buttonSmall {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &--buttonLarge {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &--tag {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-transform: uppercase;

    @include respond-to('phone') {
      font-size: 14px;
      line-height: 110%;
    }

  }

  // COLORS
  &--yellow {
    color: #efff04;
  }

  &--black {
    color: $surface-primary !important;
  }

  &--white {
    color: #F5F6F7 !important;
  }

  &--lightGrey {
    color: #f5f6f7;
  }

  &--darkGrey {
    color: #aeb4bb;
  }

  &--header {
    margin-bottom: 104px;

    @include respond-to('phone') {
      margin-bottom: 32px;
    }
  }
}

.template {
  &--BigButton {
    background: #efff04;
    padding: 10px;
  }
}

.background {
  &--black {
    background: $surface-primary !important;
  }

  &--white {
    background: #F5F6F7 !important;
  }

  &--yellow {
    background: #efff04 !important;
  }

  &--grey {
    background: #AEB4BB !important;
  }
}


.article-parameters {
  h3, h4 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  a {
    color: #fff;
    font-weight: 500;

    &:hover {
      color: #efff04;
    }
  }

  b {
    font-weight: 700;
  }

  ul {
    list-style: disc;
    padding-left: 20px;

    li {
      padding-bottom: 20px;
      padding-left: 20px;
    }
  }
}

