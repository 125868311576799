.kpi {
  &__container {
    display: flex; /* Ustawiamy kontener jako flex container */
    justify-content: center; /* Wyśrodkowanie zawartości w poziomie */
    flex-wrap: wrap; /* Zawijanie elementów do następnej linii */
    width: 100%;
    margin: 0 auto;

  }

  &__boxes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
      grid-gap: 24px;

    }
  }

  &__box {
    @include respond-to('phone') {
      margin-top: 50px;
    }

    &__text {
      height: 60px;

      @include respond-to('phone') {
        height: auto;
      }
    }

    &__link {
      margin-top: 40px;
      text-align: right;
      display: grid;

      @include respond-to('phone') {
        text-align: left;
      }

      &::after {
        background: url("img/arrow-down-skew-black.svg");
        transform: rotate(-45deg);
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 2;
        content: ' ';
        width: 24px;
        height: 24px;
      }

      a {
        & span {
          display: inline-block;
          margin-left: 1px;
          transition: 0.3s ease;
        }

        &:hover span:nth-child(1) {
          transform: translateX(3px);
        }

        &:hover span:nth-child(2) {
          transform: translateX(3px);
          transition-delay: 0.1s;
        }
      }
    }
  }

  &__logos {
    display: flex;
    justify-content: center; /* Wyśrodkowanie boxów w poziomie */
    flex-wrap: wrap; /* Zawijanie boxów do następnej linii, jeśli zajdzie taka potrzeba */
    gap: 0;
    width: 100%;
    margin: 0 auto;
    margin-top: 104px;
    margin-bottom: 104px;

    @include respond-to('phone') {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__logo {
      width: 245px;
      height: 245px;
      border-right: 1px solid #353538;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 30px;

      @include respond-to('phone') {
        border: none;
        width: 100%;
        padding: 24px;
      }

      img {
        width: 100%;

      }

      &:nth-child(1) {
        border-left: 1px solid #353538;

        @include respond-to('phone') {
          border: none;
        }
      }
    }
  }
}
