$slider-box-width-desktop: 25vw;
$slider-box-width-hd: 33.3vw;
$slider-box-width-tablet: 50vw;
$slider-box-width-phone: 70vw;

.statistics {
  background: $surface-primary;

  &__title {
    padding-bottom: 20px;
  }

  &__number {
    font-size: 28px;
    padding-bottom: 4px;
  }

  $animationSpeed: 15s;

  &__slider {
    min-height: 160px;
    margin: auto;
    overflow: hidden;
    position: relative;

    @for $x from 1 through 30 {
      .slide-track-width-#{$x} {
        display: flex;
        width: calc(#{$slider-box-width-desktop} * (#{$x} * 2));

        @include max-hd {
          width: calc(#{$slider-box-width-hd} * (#{$x} * 2));
        }

        @include max-tablet {
          width: calc(#{$slider-box-width-tablet} * (#{$x} * 2));
        }

        @include respond-to('phone') {
          width: calc(#{$slider-box-width-phone} * (#{$x} * 2));
        }
      }
    }

    @for $x from 1 through 30 {
      .slide-track-animation-#{$x} {
        @keyframes scroll-#{$x} {
          0% {
            transform: translateX(0);
          }

          100% {
            transform: translateX(($slider-box-width-desktop * $x) * -1);

            @include max-hd {
              transform: translateX(($slider-box-width-hd * $x) * -1);
            }

            @include max-tablet {
              transform: translateX(($slider-box-width-tablet * $x) * -1);
            }

            @include respond-to('phone') {
              transform: translateX(($slider-box-width-phone * $x) * -1);
            }
          }
        }

        animation: scroll-#{$x} $animationSpeed linear infinite;
      }
    }
  }

  &__slide {
    min-height: 160px;
    width: $slider-box-width-desktop;
    padding: 20px 20px 30px 30px;
    border: 1px solid $grey-dark;


    &:hover {
      strong {
        color: #efff04;
      }
    }

    strong {
      font-size: 28px;
      padding-bottom: 4px;
    }

    @include max-hd {
      width: $slider-box-width-hd;
    }

    @include max-tablet {
      width: $slider-box-width-tablet;
      padding: 10px 10px 20px 20px;

      h2 {
        font-size: 18px;
      }

      strong {
        font-size: 24px;

      }

      p {
        font-size: 14px;
      }
    }

     @include respond-to('phone') {
        width: $slider-box-width-phone;
       min-height: 70px;
      }
  }
}
