.insights-dark-background {
  background-color: $surface-primary;
}

.insights-dark-text {
  color: $white;
}

.insights-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @include respond-to('phone') {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .texts {
    flex: 1;
    flex-direction: column;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  .button,
  .button-dark {
    width: fit-content;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    gap: 8px;
    position: relative;
    font-family: 'Space Grotesk', serif;
    font-size: 20px;
    font-weight: 500;
    border: none;
    color: $white;
    background-color: $surface-primary;

    &::after {
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      background-size: 100% 100%;
      transition: 0.2s;
      background-image: url('img/arrow-white.svg');
    }

    &:hover {
      cursor: pointer;

      &::after {
        transition: 0.2s;
        transform: rotate(-45deg);
      }
    }
  }

  .button-dark {
    color: $surface-primary;
    background-color: $white;

    &::after {
      background-image: url('img/arrow.svg');
    }
  }
}

.insights-list {
  padding-top: 0;
  padding-bottom: 80px;
  display: flex;
  flex-flow: row wrap;
  gap: 32px;

  @include respond-to('phone') {
    flex-direction: column;
  }

  @include respond-to('fullhd') {
    //padding-top: 40px;
  }

  @include respond-to('phone') {
    padding-top: 0;
    padding-bottom: 50px;
  }

  @include respond-to('tablet') {
    //padding-top: 40px;
    padding-bottom: 40px;
  }

  .border {
    border: 1px solid $grey-dark;
  }

  .insights-list__element {
    width: calc(33% - 21px);

    @include respond-to('tablet') {
      width: calc(50% - 16px);
    }

    &__texts {
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 16px;
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-end;
    }

    .arrow {
      place-items: center center;
      background-size: contain;
      display: inline-grid;
      height: 24px;
      vertical-align: top;
      width: 24px;
      padding: 0 6px;
      background-color: $brand-yellow;
      opacity: 0;

      &::after {
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        background-size: 100% 100%;
        transition: 0.2s;
        background-image: url('img/arrow.svg');
        transform: rotate(-45deg);
      }
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        .arrow {
          opacity: 1;
        }
      }
    }

    &__img {
      position: relative;

      img, picture {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }

    @include respond-to('phone') {
      width: 100%;
      margin-right: 0;
      margin-top: 40px;
    }
  }
}

.insights-container {
  .container {
    padding: 0 150px !important;
    width: auto !important;
    margin: auto !important;

    @include respond-to('fullhd') {
      padding: 0 104px !important;
    }

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }
}

.insights-article {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 0;

  &:hover {
    .button {
      cursor: pointer;

      .article-arrow {
        background-color: $brand-yellow;

        &::after {
          transition: 0.2s;
          transform: rotate(-45deg);
          background-image: url('img/arrow.svg');
        }
      }
    }
  }

  .texts {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__title {
      background-color: $brand-yellow;
      color: $surface-primary;
      width: fit-content;
      padding: 4px 8px;
    }
  }

  .image-subtitle {
    background-color: $white;
    color: $surface-primary;
    border: 1px solid #000;
    position: absolute;
    bottom: -30px;
    margin: 0 20px;
    padding: 16px;
  }

  .button {
    @extend .typography--buttonSmall;

    width: 100%;
    height: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    position: relative;
    color: $surface-primary;

    .article-arrow {
      place-items: center center;
      background-size: contain;
      display: inline-grid;
      height: 24px;
      vertical-align: top;
      width: 24px;

      &::after {
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        background-size: 100% 100%;
        transition: 0.2s;
        background-image: url('img/arrow.svg');
      }
    }
  }

  .button-dark {
    color: $white;

    .article-arrow {
      &::after {
        background-image: url('img/arrow-white.svg');
      }
    }
  }
}


.insights-logo {
  
}
