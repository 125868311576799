.slideshow {
  &__container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  &__slider {
    display: flex;
    align-items: flex-start;
    cursor: grab;
    will-change: transform;

    &__slide {
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      margin-right: 32px;

      img {
        width: 100%;
        margin-bottom: 42px;
      }

      &:nth-child(odd) {
        width: 45%;

        @include respond-to('phone') {
          width: 65%;
        }
      }

      &:nth-child(even) {
        width: 33%;

        @include respond-to('phone') {
          width: 65%;
        }
      }

      &__img {
        display: block;
      }

      &__category {
        display: inline-block;
        width: fit-content;
        padding: 3px;

        @include respond-to('phone') {
          margin-bottom: 5px;
        }
      }

      &__label {
        display: inline-block;
        width: fit-content;
        padding: 3px;
      }

      a {
        display: block;
        width: 100%;
        margin-top: 32px;
      }

    }
  }

  &__controls {
    margin-top: 60px;
    width: fit-content;
    float: right;

    &__control {
      display: inline-grid;
      width: 32px;
      color: #fff;
      padding: 4px;
      cursor: grab;
      border: 1px solid #fff;
      text-align: center;
    }
  }
}
