.article-top {
  video {
    @include respond-to('phone') {
      display: none;
    }
  }

  &__padding {
    padding-bottom: 80px;
    padding-top: 80px;

    @include respond-to('phone') {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .container {
      padding: 0 90px !important;
      margin: auto !important;
      width: auto !important;

      @include respond-to('hd') {
        padding: 0 104px !important;
        width: auto !important;
      }

      @include respond-to('tablet') {
        width: 100% !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      @include respond-to('phone') {
        width: 100% !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
      }
    }
  }

  &__media {
    width: 84%;
    margin: auto;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @include respond-to('phone_tablet') {
      /* min-height: 80vh;
      height: auto;
      width: 100%; */
      display: none;
    }

    &__phone {
      display: none;

      @include respond-to('phone_tablet') {
        width: 100%;
        display: block;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__mask {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      position: absolute;

      video {
        width: 100%;
        left: 0;
        bottom: 0;
        top: 0;
        position: absolute;
      }
    }
  }

  &__left {
    display: inline-block;
    vertical-align: top;
    box-sizing: content-box;
    font-size: 0;
    width: 33.33%;
    padding-top: 10px;

    @include respond-to('phone') {
      width: 100%;
      padding-top: 0;
    }
  }

  &__author {
    width: 100%;
    display: block;
    vertical-align: top;
    margin-top: -4px;

    @include respond-to('fullhd') {
      margin-top: 4px;
    }

    @include respond-to('phone') {
      margin-top: 0;
    }

    &__img {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;

      @include respond-to('phone') {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-bottom: 30px;
      }

      @include respond-to('fullhd') {
        width: 60px;
        height: 60px;
      }
    }

    &__name {
      color: #000;
      font-size: 13px;
      font-weight: 500;
      line-height: 30px;
      padding-top: 10px;
      vertical-align: top;
      display: inline-grid;
      align-content: center;

      @include respond-to('phone') {
        padding: 0;
        height: 60px;
        align-items: center;
        flex-wrap: wrap;
        width: calc(100% - 107px);
        align-content: center;
        display: inline-flex;
      }

      & > div {
        display: block;
        vertical-align: top;
        line-height: 17px;
        width: 100%;

        &:first-child {
          font-size: 13px;

          @include respond-to('fullhd') {
            font-size: 13px;
          }
        }

        &:last-child {
          @include respond-to('fullhd') {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }
  }

  &__right {
    display: inline-block;
    vertical-align: top;
    width: 65.66%;

    @include respond-to('phone') {
      width: 100%;
    }
  }

  &__title {
    color: #000;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: -2px !important;
    line-height: 60px;
    padding-bottom: 40px;

    @include respond-to('phone') {
      font-size: 35px;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: -0.36px;
    }

    @include respond-to('fullhd') {
      font-size: 70px;
      letter-spacing: -3px;
      line-height: 86px;
      padding-bottom: 50px;
    }
  }

  &__lead {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;

    a {
      color: #ffd700;
    }

    @include respond-to('fullhd') {
      font-size: 30px;
      font-weight: 400;
      line-height: 46px;
      letter-spacing: -1px;
    }
  }
}
