.service-box {
  background: $surface-primary;
}

.service-box-header {
  padding-bottom: 104px;

  @include respond-to('tablet') {
    padding-bottom: 80px;
  }

  @include respond-to('phone') {
    padding-bottom: 40px;
  }

  &__subtitle {
    padding-bottom: 8px;
  }

  &__list {
    display: flex;

    @include respond-to('tablet') {
      flex-wrap: wrap;
      gap: 8px;
    }

    @include respond-to('phone') {
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  &__column {
    width: 33.33%;
    max-width: none !important;

    @include respond-to('tablet') {
      width: 49%;
    }

    @include respond-to('phone') {
      width: 100%;
    }

    &--text {
      padding-left: 32px;

      @include respond-to('tablet') {
        &:first-of-type {
          padding-left: 0;
        }
      }

      @include respond-to('phone') {
        padding-left: 0;
      }
    }

    &--title {
      @include respond-to('tablet') {
        width: 100%;
      }

      @include respond-to('phone') {
        width: 100%;
      }

      @media screen and (width <= 420px) {
        font-size: 36px;
      }
    }
  }
}

.service-box-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &--reversed {
    flex-direction: row-reverse;
  }

  &__list {
    width: 50%;

    @include respond-to('phone') {
      width: 100%;
    }
  }

  &__image-wrapper {
    width: 35%;

    @include respond-to('tablet') {
      width: 50%;
      padding-right: 32px;
    }

    @include respond-to('phone') {
      width: 100%;
      margin-top: 48px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 5 / 3;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  &__list-title {
    border-bottom: 1px solid #353538;
    padding-bottom: 8px;
  }

  &__link {
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #353538;
    display: flex;
    align-items: center;
    color: $text-primary !important;

    &:hover {
      color: $text-primary !important;
      border-bottom: $grey-medium solid #aeb4bb;
      text-decoration: none !important;

      &::after {
        background-size: 8px;
        background: url('img/arrow.svg') no-repeat center;
      }

      &::before {
        display: block;
      }
    }

    &::before {
      content: '';
      display: none;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 0;
      background: white;
    }

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 0;
      background-size: 8px;
      background-image: url('img/arrow-white.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

