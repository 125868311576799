.case-studies-new {
  background: $surface-primary;
  padding: 0 150px 120px !important;
  margin: auto !important;
  width: auto !important;

  @include respond-to('fullhd') {
    padding: 0 104px 120px !important;
  }

  @include respond-to('tablet') {
    width: 100% !important;
    padding: 0 30px 120px !important;
  }

  @include respond-to('phone') {
    width: 100% !important;
    padding: 0 30px 120px !important;
  }
}

.case-studies-list-padding {
  padding-bottom: 104px;

  @include respond-to('phone') {
    padding-bottom: 36px;
  }
}

.case-studies-filter {
  padding-top: 44px;
  padding-bottom: 104px;


  @include respond-to('phone') {
    padding-top: 4px;
    padding-bottom: 64px;
  }

  &__title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    h2 {
      margin-top: 8px;
    }

    a {
      width: 360px;
    }

    @media screen and (width <= 1023px) {
      flex-direction: column;
      align-items: flex-start;

      a {
        width: 100%;
      }
    }
  }

  &__list-wrapper {
    p {
      margin-bottom: 24px;
    }
  }
}

.case-studies-filter-list {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $grey-dark;
  border-left: 1px solid $grey-dark;

  &__item {
    width: 25%;
    border-right: 1px solid $grey-dark;
    border-bottom: 1px solid $grey-dark;

    @media screen and (width <= 1023px) {
      width: 33.33%;
    }

    @media screen and (width <= 767px) {
      width: 50%;
    }

    @media screen and (width <= 500px) {
      width: 100%;
    }
  }
}

.case-studies-box {
  display: flex;
  gap: 16px;
  margin-bottom: 120px;

  @include respond-to('phone') {
    margin-bottom: 96px;
    display: grid;
    grid-gap: 32px;
    grid-template-areas: "b"
                          "a";

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 96px;
    padding-right: 96px;
    font-size: 10px;
    min-height: 466px;

    @media screen and (width <= 1599px) {
      font-size: 8px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @media screen and (width <= 1023px) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      font-size: 9px;
      padding-bottom: 20px;
      grid-area: a;
    }

    @media screen and (width <= 767px) {
      padding-bottom: 0;
      min-height: auto;
    }
  }

  &__image {
    grid-area: b;
    display: grid;
  }

  &__tag-list {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 2.4em;
    }
  }

  &__tag {
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    background: $brand-yellow;
    color: $surface-primary !important;
    margin-bottom: 10px;

    @include respond-to('phone') {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    margin: 1.5em 0;

    @include respond-to('phone') {
      margin: 32px 0;
    }
  }

  &__opinion {
    border-left: 1px solid $grey-medium;
    padding-left: 4.2em;

    @media screen and (width <= 419px) {
      padding-left: 0;
      border-left: 0;
    }
  }

  &__opinion-text {
    margin-bottom: 1.6em;

    @include respond-to('phone') {
      margin-bottom: 32px;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__author-img {
    width: 6.9em;
    height: 6.9em;
    margin-right: 2em;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__details {
    @include respond-to('phone') {
      width: 70%;
      display: grid;
    }
  }

  &__author-name {
    display: block;

    &:hover {
      cursor: pointer;
    }
  }

  &__author-company {
    margin-top: 0.5em;
    color: $grey-medium !important;

    &:hover {
      cursor: pointer;
    }
  }

  &__author-linkedin {
    position: absolute;
    right: 0;
    width: 28px;
    height: 28px;
  }
}

.case-studies-list {
  li:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media screen and (width <= 1023px) {
    li {
      flex-direction: column;
      max-height: none !important;
    }

    li:nth-child(even) {
      flex-direction: column;
      max-height: none !important;
    }
  }
}

.case-study-image {
  width: 50%;
  position: relative;
  cursor: url('img/cursor.svg'), auto;

  &.not-video {
    opacity: 1 !important;
  }

  @include respond-to('phone') {
    height: auto;
    width: 100%;
  }

  &:hover {
    .case-study-image__first-image {
      opacity: 0 !important;

      @include respond-to('phone') {
        opacity: 1 !important;
      }

      &.not-video {
        opacity: 1 !important;
      }
    }

  }

  &__logo-image {
    cursor: url('img/cursor.svg'), auto;
    width: 100%;
    height: auto;
  }

  &__first-image {
    position: absolute;
    width: 100%;
    height: auto;
    background: white;
    top: 0;
    left: 0;
    z-index: 990;

    @include respond-to('phone') {
      width: 100%;
      height: auto;
      aspect-ratio: 4 / 3;
    }
  }

  .video-case {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 980;

    @include respond-to('phone') {
      display: none;
      width: 100%;
      height: auto;
      aspect-ratio: 4 / 3;
    }
  }

  &__second-image {
  }
}

.case-study-loader {
  padding: 80px 0;
}
