.cookie {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 1000;
  transform: translate3d(105%, 0, 0);
  transition: 0.25s ease;

  @include respond-to('phone') {
    width: 100%;
    max-width: unset;
  }

  &__header__container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  max-width: 400px;

  &.active {
    transform: translate3d(0, 0, 0);
    transition: 0.25s ease;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    border: 1px solid #262728;
    background: #0b0a0e;
    color: white;

    &__header {
      font-size: 20px;
    }

    &__text {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: white;
    }

    &__button {
      background-color: white;
      color: black;
      height: 50px;
      border: none;
    }

    &__button__secondary {
      @extend .cookie__container__button;

      background-color: $og-black;
      color: white;
    }
  }

  &__link {
    color: $og-gray;
    opacity: 0.9;
    cursor: pointer;
    transition: 0.25s ease;
    text-decoration: none;

    &:hover {
      opacity: 1;
      transition: 0.25s ease;
    }
  }
}
