.slider-picture {
  position: relative;
  z-index: 1;
  width: 80%;

  .case-study-image__second-image {
    width: 100%;
  }
}

.main-container {
  color: $text-primary;
}

.section-container--white {
  .tiny-slider-arrows {
    .arrow {
      color: $surface-primary;
      border-color: $surface-primary;
    }
  }
}

.top-text {
  display: flex;
  justify-content: space-between;

  &__col_1 {
    width: 30%;
    margin-right: 3%;
  }

  &__col_2,
  &__col_3 {
    width: 29%;
    margin-right: 2%;
  }
}

.carousel-wrapper {
  padding-left: 150px;
  padding-top: 100px;

  @include respond-to('fullhd') {
    padding-left: 180px;
  }

  @include respond-to('tablet') {
    padding-left: 30px;
  }

  @include respond-to('phone') {
    padding-left: 30px;
  }

  .tiny-slider {
    position: relative;
    margin-bottom: 80px;
  }

  .tags {
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;

    .tag {
      background-color: $brand-yellow;
      color: $surface-primary;
      font-size: 16px;
      text-transform: uppercase;
      padding: 4px 8px;
      margin-right: 16px;
    }
  }
}

.tiny-slider-arrows {
  display: flex;
  justify-content: flex-end;

  .arrow {
    background-color: transparent;
    width: 48px;
    height: 48px;
    border: 1px solid $surface-invert;
    color: $text-primary;
    margin-left: 32px;

    &:hover {
      cursor: pointer;
    }
  }
}
