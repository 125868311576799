.blog {
  background-color: rgb(239 239 239);
  width: 100%;
  padding-bottom: 100px;

  &--white {
    background: #fff;
    padding-bottom: 0;
    padding-top: 0;
  }

  @include respond-to('phone') {
    padding-bottom: 50px;
    padding-top: 0;
  }

  &__left {
    padding-top: 100px;
    display: inline-block;
    vertical-align: top;
    width: 70%;

    @include respond-to('phone') {
      width: 100%;
      padding-top: 50px;
    }

    &__topic {
      @include style-element('h3');
    }

    &__description {
      @include style-element('p');

      margin-top: 15px;
    }
  }

  &__right {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    float: right;
    padding-top: 100px;
    text-align: right;

    @include respond-to('phone') {
      display: none;
    }
  }

  &__all {
    margin-top: 50px;
    display: block;
    vertical-align: top;

    &__one {
      display: inline-block;
      width: 370px;
      vertical-align: top;
      margin-right: 24px;

      @include respond-to('tablet') {
        width: 330px;
        margin-right: 21px;

        &:last-child {
          display: none;
        }
      }

      @include respond-to('phone') {
        width: 100%;
        margin-top: 50px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(n + 4) {
        margin-top: 100px;

        @include respond-to('phone') {
          margin-top: 0;
        }
      }

      &__container {
        width: 370px;
        height: 200px;
        overflow: hidden;

        @include respond-to('phone') {
          width: 100%;
          height: auto;
        }

        @include respond-to('tablet') {
          width: 343px;
          height: 186px;
        }
      }

      &__img {
        width: 370px;
        height: 200px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: all 0.6s;

        @include respond-to('phone') {
          width: 100%;
          height: 270px;
        }

        @include respond-to('tablet') {
          width: 343px;
          height: 186px;
        }
      }

      &__info {
        margin-top: 0;
        margin-bottom: 30px;
        display: block;
        vertical-align: top;
        width: 100%;

        &__img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-size: cover;
          background-position: center center;
          display: inline-block;
          vertical-align: top;
        }

        &__infotxt {
          display: inline-block;
          vertical-align: top;
          margin-left: 10px;
          width: calc(100% - 70px);

          &__author {
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            text-transform: uppercase;
            color: #000;
          }

          &__topic {
            vertical-align: top;
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
            color: rgb(0 0 0 / 70%);
          }
        }
      }

      &__topic {
        @include style-element('h4');

        text-transform: none;
        color: #000;
      }

      &__lead {
        @include style-element('p');

        margin-top: 50px;
        color: #27292a;
        height: 80px;

        @include respond-to('phone') {
          margin-top: 20px;
        }
      }

      &:hover {
        .blog__all__one__img {
          transform: scale(1.2);
        }
      }
    }
  }

  &__special {
    display: block;
    width: 100%;
    vertical-align: top;
    margin-top: 100px;
    margin-bottom: 100px;

    @include respond-to('phone') {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &__left {
      display: inline-block;
      vertical-align: top;

      img {
        width: 670px;
        height: 370px;

        @include respond-to('phone') {
          width: calc(100% + 40px);
          margin-left: -25px;
          height: auto;
        }
      }
    }

    &__right {
      width: calc(100% - 706px);
      display: inline-block;
      vertical-align: top;
      margin-left: 30px;

      @include respond-to('phone') {
        width: 100%;
        margin-left: 0;
      }

      &__box {
        width: 100%;
        display: block;
        vertical-align: top;
        height: 170px;

        @include respond-to('phone') {
          height: auto;
        }

        &:first-child {
          margin-bottom: 30px;
        }

        &__img {
          width: 170px;
          height: 170px;
          display: inline-block;
          vertical-align: top;

          @include respond-to('phone') {
            display: none;
          }
        }

        &__info {
          margin-left: 30px;
          display: inline-block;
          vertical-align: top;
          width: calc(100% - 206px);

          @include respond-to('phone') {
            width: 100%;
            margin-left: 0;
          }

          &__topic {
            color: #27292a;
            font-size: 19px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: -0.16px;

            @include respond-to('phone') {
              margin-top: 30px;
            }
          }

          &__lead {
            color: #27292a;
            font-size: 15px;
            font-weight: 400;
            margin-top: 15px;
            line-height: 25px;

            @include respond-to('phone') {
              display: none;
            }
          }
        }
      }
    }
  }

  &__filters {
    display: table-cell;
    vertical-align: top;
    padding-top: 30px;
    margin-bottom: -50px;

    @include respond-to('phone') {
      margin-bottom: 0;
      padding: 0;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @include respond-to('fullhd') {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &__element {
      display: inline-block;
      vertical-align: top;
      margin-right: 35px;

      @include respond-to('phone') {
        margin-right: 10px;
      }

      &__href {
        font-size: 12px;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
        color: #000;

        @include respond-to('fullhd') {
          font-size: 16px;
        }

        &--active {
          color: #e4a044;
        }
      }
    }
  }
}

.blog-all {
  .container {
    padding: 0 50px !important;
    margin: auto !important;
    width: auto !important;

    @include respond-to('hd') {
      padding: 0 150px !important;
      width: auto !important;
    }

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }
}
