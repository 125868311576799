@mixin respond-to($media) {
  @if $media == phone {
    @media only screen and (max-width: $break-tablet) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $break-tablet + 1) and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $break-desktop) {
      @content;
    }
  } @else if $media == hd {
    @media only screen and (min-width: $break-full-hd) {
      @content;
    }
  } @else if $media == fullhd {
    @media only screen and (min-width: $max-full) {
      @content;
    }
  } @else if $media == small_mobile {
    @media only screen and (width <= 374px) {
      @content;
    }
  } @else if $media == small_mobile_height {
    @media only screen and (width <= 374px) and (height <= 570px) {
      @content;
    }
  } @else if $media == phone_tablet {
    @media only screen and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media == hd_plus {
    @media only screen and (width <= 1599px) {
      @content;
    }
  } @else if $media == wxga {
    @media only screen and (width <= 1439px) {
      @content;
    }
  } @else if $media == max_height {
    @media only screen and (width >= 1900px) {
      @content;
    }
  }

}

@mixin max-tablet() {
  @media only screen and (max-width: $break-tablet) {
    @content;
  }
}

@mixin max-hd() {
  @media only screen and (max-width: $break-hd) {
    @content;
  }
}

@mixin max-phone-lg() {
  @media only screen and (max-width: $phone-lg) {
    @content;
  }
}

@mixin max-desktop {
  @media only screen and (max-width: $break-desktop - 1) {
    @content;
  }
}

@mixin max-full-hd {
  @media only screen and (max-width: $break-full-hd - 1) {
    @content;
  }
}

@mixin media-wqxga() {
  @media only screen and (width <= 1600px) {
    @content;
  }
}

@mixin max-hd-plus() {
  @media screen and (width <= 1599px) {
    @content;
  }
}

@mixin max-wsvga() {
  @media screen and (width <= 1023px) {
    @content;
  }
}

@mixin max-wxga() {
  @media screen and (width <= 1279px) {
    @content;
  }
}

@mixin max-tablet() {
  @media screen and (width <= 768px) {
    @content;
  }
}

@mixin max-tablet() {
  @media only screen and (max-width: $break-tablet) {
    @content;
  }
}

@mixin max-hd() {
  @media only screen and (max-width: $break-hd) {
    @content;
  }
}

@mixin max-phone-lg() {
  @media only screen and (max-width: $phone-lg) {
    @content;
  }
}

@mixin max-desktop {
  @media only screen and (max-width: $break-desktop - 1) {
    @content;
  }
}

@mixin max-full-hd {
  @media only screen and (max-width: $break-full-hd - 1) {
    @content;
  }
}

@mixin media-wqxga() {
  @media only screen and (width <= 1600px) {
    @content;
  }
}

@mixin max-hd-plus() {
  @media screen and (width <= 1599px) {
    @content;
  }
}

@mixin max-wsvga() {
  @media screen and (width <= 1023px) {
    @content;
  }
}

@mixin max-wxga() {
  @media screen and (width <= 1279px) {
    @content;
  }
}

@mixin max-tablet() {
  @media screen and (width <= 768px) {
    @content;
  }
}

/* font-size */

@mixin style-element($type) {
  @if $type == h1 {
    font-size: 45px !important;
    font-weight: 600 !important;
    line-height: 60px !important;
    letter-spacing: -0.47px !important;

    @include respond-to('tablet') {
      font-size: 45px !important;
      font-weight: 600 !important;
      line-height: 50px !important;
      letter-spacing: -0.36px !important;
    }

    @include respond-to('phone') {
      font-size: 31px !important;
      font-weight: 600 !important;
      line-height: 40px !important;
      letter-spacing: -0.42px !important;
    }
  }

  @if $type == h2 {
    font-size: 35px !important;
    font-weight: 300 !important;
    letter-spacing: -0.49px !important;
    line-height: 60px !important;

    @include respond-to('tablet') {
      font-size: 31px !important;
      font-weight: 300 !important;
      line-height: 50px !important;
      letter-spacing: -0.43px !important;
    }

    @include respond-to('phone') {
      font-size: 23px !important;
      font-weight: 300 !important;
      line-height: 40px !important;
      letter-spacing: -0.32px !important;
    }
  }

  @if $type == h3 {
    font-size: 25px !important;
    font-weight: 600 !important;
    line-height: 35px !important;
    text-transform: uppercase !important;
    letter-spacing: -0.59px !important;

    @include respond-to('tablet') {
      font-size: 23px !important;
      font-weight: 600 !important;
      line-height: 32px !important;
      text-transform: uppercase !important;
      letter-spacing: -0.54px !important;
    }

    @include respond-to('phone') {
      font-size: 18px !important;
      font-weight: 600 !important;
      line-height: 35px !important;
      text-transform: uppercase !important;
      letter-spacing: -0.42px !important;
    }
  }

  @if $type == h4 {
    font-size: 19px;
    font-weight: 500;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: -0.16px;
  }

  @if $type == 'p' {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }

  @if $type == 'blog' {
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;

    @include respond-to('phone') {
      font-size: 17px;
      font-weight: 400;
      line-height: 25px;
    }
  }

  @if $type == 'footer' {
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;

    @include respond-to('tablet') {
      font-size: 16px;
      font-weight: 400;
      line-height: 40px;
    }
  }

  @if $type == 'blog_info' {
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
  }
}
