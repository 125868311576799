.clutch {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;

  .container {
    padding: 0 150px !important;
    margin: auto !important;
    width: auto !important;

    @include respond-to('fullhd') {
      padding: 0 104px !important;
    }

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }

  &__half {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 64px);
    padding-top: 0;
    padding-bottom: 0;

    @include respond-to('phone') {
      width: 100%;
      margin-top: 25px;
    }

    &:first-child {
      margin-right: 120px;

      @include respond-to('phone') {
        margin-right: 0;
      }
    }

    h2 {
      font-size: 45px;
      font-family: 'Space Grotesk', sans-serif;
      font-weight: 400;
      letter-spacing: -2px;
      line-height: 50px !important;
      padding-top: 0;
      padding-bottom: 0;

      @include respond-to('phone') {
        text-align: center;
      }
    }

    &__subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    &__testimonial {
      width: 100%;
      margin-bottom: 70px;

      a {
        &:hover {
          .details__body__testimonial__info__img img {
            transform: rotate(-45deg);
            transition: all 0.3s;
          }
        }
      }

      .article-top__author__img {
        @include respond-to('phone') {
          margin-bottom: 0;
        }
      }

      .details__body__testimonial__info {
        width: 100%;

        &__img {
          border-radius: 3px;
          height: 60px;
          width: 60px;
          margin-right: 20px;
          position: relative;
        }

        &__icon {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 20px;
          height: 20px;
          background-color: #efff04;
          display: grid;
          place-items: center center;
        }
      }

      .details__body__testimonial__txt {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Work Sans', sans-serif;

        @include respond-to('fullhd') {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }
}
