.hero-minimal-top {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 33%;
  padding-top: 204px;
    padding-bottom: 100px;



  @include respond-to('phone') {
    padding: 124px 0 80px;
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }

  &.padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  &--black {
    background: $surface-primary;


    h1,
    p {
      color: $text-primary !important;
    }
  }

  &__left {
    display: grid;
    align-content: space-between;

    &--top {
      padding-top: 40px;
    }
  }

  &__right {

  }




  .hero-minimal-main-container-left {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-to('desktop') {
      justify-content: space-between;
      flex-direction: row;
      width: calc(100% / 3) !important;
    }
  }

  .hero-minimal-main-container-right {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    justify-content: center;

    @include respond-to('desktop') {
      justify-content: space-between;
      width: calc(100% / 3) !important;
    }

    ul {
      display: flex;
      flex-direction: column;
    }

    &__list-title {
      border-bottom: 1px solid $grey-dark;
      padding-bottom: 8px;
    }

    &__link {
      position: relative;
      padding: 10px 0;
      border-bottom: 1px solid $grey-dark;
      display: flex;
      align-items: center;
      color: $text-primary;

      &:hover {
        border-bottom: 1px solid $grey-medium;
        text-decoration: none;

        &::after {
          background-size: 8px;
          background: url('img/arrow.svg') no-repeat center;
        }

        &::before {
          display: block;
        }
      }

      &::before {
        content: '';
        display: none;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 0;
        background: $text-primary;
      }

      &::after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 0;
        background-size: 8px;
        background-image: url('img/arrow-white.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .hero-minimal-minor-container {
    display: flex;
    justify-content: space-between;

    @include respond-to('phone') {
      flex-direction: column;
      gap: 20px;
    }
  }

  .hero-minimal-minor-container-left {
    width: calc(100% / 3) !important;

    @include respond-to('phone') {
      width: 100% !important;
    }
  }

  .hero-minimal-minor-container-right {
    width: calc(100% / 3) !important;

    @include respond-to('phone') {
      width: 100% !important;
    }

    .hero-minimal-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $brand-yellow;

      .hero-minimal-button-text {
        text-align: left;
        padding: 24px;
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $surface-primary;

        @include respond-to('phone') {
          padding: 10px;
        }

        @include respond-to('tablet') {
          padding: 14px;
          font-size: 16px;
        }
      }

      .hero-minimal-button-img {
        width: 32px;
        padding: 9px;
        margin-right: 20px;
      }
    }
  }

  &__image {
    height: 547px;

    @include respond-to('phone') {
      height: 30vh;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.case-study-top-images {
  display: flex;
  overflow: hidden;
  background: white;

  @include respond-to('phone') {
    flex-direction: column;
  }

  &_first {
    width: 40%;

    @include respond-to('phone') {
      width: 100%;

      img {
        height: auto;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &_second {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to('phone') {
      width: 100%;

      img {
        width: 80%;
      }
    }

    img {
      width: 50%;
      object-fit: cover;
    }
  }
}
