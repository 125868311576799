.download-section {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 430px;
  background-color: #222;
  grid-template-areas: 'form image';
  font-family: 'Work Sans', sans-serif;

  @include respond-to('hd') {
    grid-template-rows: 570px;
  }

  @include respond-to('tablet') {
  }

  @include respond-to('phone') {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas: 'image' 'form';
    padding-bottom: 20px;
  }

  &__form {
    position: relative;
    margin: 40px 60px 60px;
    grid-area: form;

    @include respond-to('hd') {
      margin: 60px 120px 100px;
    }

    @include respond-to('tablet') {
      margin: 30px 40px 50px;
    }

    @include respond-to('phone') {
      margin: 30px 40px 50px;
    }

    &__title {
      color: #efff04;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      text-transform: uppercase;
      padding-bottom: 13px;

      @include respond-to('hd') {
        font-size: 14px;
      }

      @include respond-to('phone') {
        padding-bottom: 6px;
      }
    }

    &__text {
      color: #fff;
      font-size: 25px;
      font-weight: 400;
      letter-spacing: -0.59px;
      line-height: 35px;

      @include respond-to('hd') {
        font-size: 32px;
        font-weight: 400;
        letter-spacing: -0.75px;
        line-height: 45px;
      }

      @include respond-to('tablet') {
        font-size: 25px;
        letter-spacing: -0.52px;
        line-height: 35px;
      }

      @include respond-to('phone') {
        font-size: 22px;
        letter-spacing: -0.52px;
        line-height: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    &__text-small {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    &__container {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      @include respond-to('phone') {
        position: relative;
      }

      &__label {
        display: block;
        width: 100%;
        color: #e2e2e2;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;

        @include respond-to('phone') {
          font-size: 12px;
          line-height: 25px;
        }
      }

      &__input {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 2px;
        background-color: transparent;
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: -0.12px;
        line-height: 25px;
        font-family: 'Work Sans', sans-serif;
        border: 1px solid #e2e2e2;
        margin-bottom: 20px;

        &:focus {
          outline: none;
        }

        &::input-placeholder {
          color: #fff;
          font-size: 15px;
          font-weight: 400;
        }
      }

      &__button {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 2px;
        background-color: #efff04;
        color: #000;
        font-size: 12px;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
        border: 1px solid #efff04;
        cursor: pointer;
        appearance: none;
        opacity: 1;

        @include respond-to('hd') {
          font-size: 14px;
        }
      }
    }
  }

  &__image {
    grid-area: image;

    &--popup {
      @include respond-to('phone') {
        display: none;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.popup-download {
  display: none;
  position: fixed;
  top: 0;
  z-index: 999999;

  @include respond-to('phone') {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

.layer-popup {
  background: rgb(0 0 0 / 85%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 99999;
}

.popup-content {
  position: relative;
}

.close-popup {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0.7;

  @include respond-to('phone') {
    top: 20px;
    right: 47px;
  }
}

.close-popup:hover {
  opacity: 1;
}

.close-popup::before,
.close-popup::after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 4px;
  background-color: #fff;
}

.close-popup::before {
  transform: rotate(45deg);
}

.close-popup::after {
  transform: rotate(-45deg);
}

.download-box {
  padding: 80px 120px;
  width: 100%;
  background: #231f2e;

  &__container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 240px;

    &__text {
      color: #fff;

      h2 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 140%;
        letter-spacing: -0.02em;
        margin-bottom: 20px;
      }

      .p {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        margin-bottom: 30px;
      }

      .download-section__form__container {
        position: relative !important;
      }
    }

    &__img {
      display: grid;
      align-items: center;
    }
  }
}
