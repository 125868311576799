$button-height: 60px;
$box-width: 300px;
$box-height: 212px;

.article {
  background: #0b0a0e;
  font-family: 'Work Sans', sans-serif;

  .container {
    padding: 0 80px 100px;

    @include respond-to('fullhd') {
      padding: 0 104px;
    }

    @include respond-to('phone') {
      padding: 0 16px;
    }
  }

  &.case-studies {
    border-bottom: 1px solid #2b2832;
    padding-bottom: 60px;

    .container {
      width: auto !important;

      @include respond-to('phone') {
        padding: 0 16px;
      }
    }
  }

  &__header {
    margin-top: 0;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 3fr;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
      margin-top: 20px;
    }

    &__left {
      &--margin-bottom {
        @include respond-to('phone') {
          margin-bottom: 32px;
        }
      }

      &__author {
        color: #fff;
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;

        .article-top__author__img {
          width: 60px !important;
          height: 60px !important;
        }
      }

      &__info {
        display: grid;
        grid-template-columns: 70px 200px;
        grid-gap: 15px;
        margin-top: 35px;

        @include respond-to('phone') {
          margin-top: 0;
        }

        &__tag {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          margin-bottom: 4px;
          text-transform: uppercase;
          color: #efff04;
        }

        &__circle {
          width: 60px;
          border-radius: 50%;
          height: 60px;
          background: rgb(255 255 255 / 10%);
          position: relative;
          display: grid;
          place-content: center center;
          margin-right: 20px;

          @include respond-to('phone') {
            width: 60px;
            height: 60px;
          }

          &__img {
            background: url('img/icon-article.svg');
            width: 20px;
            height: 20px;
            text-align: center;

            //position: absolute;
            top: 24px;
            left: 24px;
          }
        }

        &__category {
          color: #fff;
          display: grid;
          align-items: center;
          align-content: center;
          font-size: 13px;
        }
      }
    }

    &__right {
      margin-bottom: 40px;

      &__title {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 60px;
        letter-spacing: -0.05em;
        color: #fff;

        @include respond-to('phone') {
          margin-top: 25px;
          font-size: 32px;
          line-height: 50px;
        }
      }

      &__lead,
      p {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.05em;
        color: rgb(255 255 255 / 80%);
        margin-top: 25px;
      }
    }

    &__share {
      display: none;
      margin-bottom: 32px;

      @include respond-to('phone') {
        display: grid;

        .share-buttons {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          justify-items: center;

          .share-button {
            display: block;
            position: relative;
            height: $button-height;

            i {
              font-size: 25px;
              justify-content: center;
              display: grid;
              color: #fff;
            }

            &:hover {
              cursor: pointer;

              .share-button-primary {
                box-shadow: 1px 0 0 0 rgb(0 0 0 / 10%);
                background: #fff;
              }

              i {
                color: #000;
              }

              .share-button-secondary-content {
                transform: translate3d(0, 0, 0);
              }
            }
          }

          .share-button-primary {
            background: rgb(255 255 255 / 10%);
            width: $button-height;
            height: $button-height;
            border-radius: calc($button-height / 2);
          }

          .share-button-icon {
            display: block;
            color: #242424;
            width: $button-height;
            line-height: $button-height;
            font-size: 16px;
            margin-top: 1px;
          }
        }
      }
    }
  }

  &__image {
    display: grid;
    margin-top: 107px;
    margin-bottom: 80px;
    width: 100%;

    @include respond-to('phone') {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
      display: block;
      max-height: 381px;
      object-fit: cover;
    }

    picture {
      width: 100%;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 60px 1fr 320px;
    grid-gap: 60px;
    color: #fff;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
    }

    &.case-studies-body {
      grid-template-columns: 70% 30%;
      justify-content: space-between;

      @include respond-to('phone') {
        grid-template-columns: 1fr;
      }
    }

    &__share {
      @include respond-to('phone') {
        display: none;
      }

      //share plugin

      $button-height: 60px;
      $box-width: 300px;
      $box-height: 212px;

      .share-buttons {
        position: sticky;
        top: 40px;

        .share-button {
          margin-top: 15px;

          &:first-child {
            margin-top: 0;
          }

          &::after {
            clear: both;
            display: table;
          }
        }
      }

      .share-button {
        display: block;
        position: relative;
        height: $button-height;

        i {
          font-size: 25px;
          justify-content: center;
          display: grid;
          color: #fff;
        }

        &:hover {
          cursor: pointer;

          .share-button-primary {
            box-shadow: 1px 0 0 0 rgb(0 0 0 / 10%);
            background: #fff;
          }

          i {
            color: #000;
          }

          .share-button-secondary-content {
            transform: translate3d(0, 0, 0);
          }
        }


      }

      .share-button-primary {
        background: rgb(255 255 255 / 10%);
        width: $button-height;
        height: $button-height;
        border-radius: calc($button-height / 2);
      }


      .share-button-icon {
        display: block;
        color: #242424;
        width: $button-height;
        line-height: $button-height;
        font-size: 16px;
        margin-top: 1px;
      }
    }

    &__content {
      &__toc {
        margin-bottom: 40px;

        &__title {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          text-transform: uppercase;
          color: rgb(255 255 255 / 80%);
          margin-bottom: 40px;
        }

        li {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 19px;
          line-height: 25px;
          letter-spacing: -0.02em;
          color: #efff04;
          margin-bottom: 20px;
          cursor: pointer;

          &::before {
            content: '';
            display: inline-block;
            height: 1em;
            width: 1em;
            background: url('img/arrow-toc.svg') 3px 5px;
            background-repeat: no-repeat;
            margin-right: 0.5em;
          }
        }
      }

      &__text {
        p {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 19px;
          line-height: 32px;
          letter-spacing: -0.02em;
          color: #fff;
          margin-bottom: 30px;
        }

        h2 {
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          line-height: 35px;
          letter-spacing: -0.02em;
          color: #fff;
          margin-bottom: 30px;
          margin-top: 40px;
          font-family: 'Space Grotesk', sans-serif;
        }

        h3 {
          font-style: normal;
          font-weight: normal;
          font-size: 23px;
          line-height: 30px;
          letter-spacing: -0.02em;
          color: #fff;
          margin-bottom: 30px;
          margin-top: 40px;
          font-family: 'Space Grotesk', sans-serif;
        }

        h4 {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #fff;
          margin-bottom: 30px;
          margin-top: 40px;
          font-family: 'Space Grotesk', sans-serif;
        }

        i {
          font-style: italic !important;
        }

        img {
          width: 100% !important;
          height: 100% !important;
        }

        ul {
          margin-top: 40px;
          margin-bottom: 40px;

          li {
            font-style: normal;
            font-weight: normal;
            font-size: 19px;
            line-height: 25px;
            margin-bottom: 30px;
            letter-spacing: -0.02em;
            color: #fff;
            margin-left: 52px;

            &::before {
              content: '';
              display: inline-block;
              height: 1em;
              width: 1em;
              background: url('img/tick-blog.svg') no-repeat 6px -1px;
              list-style-type: none;
              margin: 0;
              padding: 0 0 1px 52px;
              vertical-align: middle;
              margin-left: -52px;
            }
          }
        }

        b {
          font-weight: 600;
        }

        a {
          font-style: normal;
          font-weight: 600;
          font-size: 19px;
          line-height: 32px;
          letter-spacing: -0.02em;
          text-decoration-line: underline;
          color: #efff04;
        }

        ol {
          margin-top: 40px;
          margin-bottom: 40px;
          font-style: normal;
          font-weight: 900;
          font-size: 19px;
          line-height: 100px;
          letter-spacing: -0.02em;
          color: #fff;
          list-style-type: decimal;
          list-style-position: inside;

          li {
            font-style: normal;
            font-weight: normal;
            font-size: 19px;
            line-height: 32px;
            padding: 30px;
            letter-spacing: -0.02em;
            border-bottom: 1px solid rgb(255 255 255 / 10%);

            @include respond-to('phone') {
              padding-left: 10px;
              padding-right: 10px;
            }

            &::marker {
              padding-left: 30px;
            }
          }
        }
      }
    }

    &__banner {
      &__box {
        position: sticky;
        top: 40px;

        &__one {
          padding: 10px;
          width: 100%;
          margin-bottom: 20px;

          img {
            width: 100%;
            display: block;
          }

          &__button {
            width: 100%;
            height: 80px;
            text-align: center;
            transition: all ease-out 0.2s;
            display: grid;
            align-content: center;
            text-transform: uppercase;
            color: #fff;
          }
        }
      }
    }
  }

  &__footer {
    margin-top: 80px;
    display: grid;
    width: 100%;

    @include respond-to('phone') {
      margin-top: 60px;
    }

    &__title {
      font-family: 'Space Grotesk', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 35px;
      letter-spacing: -0.02em;
      color: #fff;
    }

    &__hub {
      grid-template-columns: 1fr 1fr 1fr;
      display: grid;
      column-gap: 50px;
      margin-top: 60px;

      @include respond-to('phone') {
        grid-template-columns: 1fr;
        column-gap: 0;
      }
    }
  }
}

.mobile-container-blog {
  @include respond-to('phone') {
    width: 100%;
    height: 95px;
    overflow: hidden;
    margin-bottom: 30px;
  }
}

.blog-page {
  font-family: 'Work Sans', sans-serif;

  &__header {
    margin-top: 150px;

    h1 {
      font-family: 'Space Grotesk', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 45px;
      line-height: 40px;
      letter-spacing: -0.03em;
      color: #fff;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      line-height: 30px;
      letter-spacing: -0.03em;
      color: #fff;
      margin-top: 28px;
      max-width: 800px;

      @include respond-to('phone') {
        width: 100%;
      }
    }
  }

  &__filter {
    margin-top: 40px;
    margin-bottom: 40px;

    ul {
      display: flex;
      flex-wrap: wrap;

      @include respond-to('phone') {
        height: 130px; /* 40px - more place for scrollbar, is hidden under parent box */
        padding: 5px;
        white-space: nowrap;
        overflow: scroll hide;
        -webkit-overflow-scrolling: touch;
        display: block;
      }

      li {
        display: inline-grid;
        min-width: fit-content;
        margin-bottom: 10px;
        margin-right: 10px;

        a {
          padding: 15px;
          border: 1px solid #676868;
          box-sizing: border-box;
          column-gap: 30px;
          align-items: center;
          text-transform: uppercase;
          font-size: 13px;
          color: rgb(255 255 255 / 80%);

          &:hover {
            background: #fff;
            color: #13101b;
            border: 1px solid #fff;
          }

          &.active {
            background: #fff;
            color: #13101b;
            border: 1px solid #fff;
          }
        }
      }
    }
  }

  &__body {
    margin-top: 40px;

    @include respond-to('phone') {
      margin-top: 30px;
    }

    &__promo {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 100px;
      margin-bottom: 100px;

      @include respond-to('phone') {
        grid-template-columns: 1fr;
        gap: 0;
      }

      &__image {
        width: 100%;

        img {
          width: 100%;
        }
      }

      &__info {
        font-family: 'Work Sans', sans-serif;
        font-size: 13px;

        &__stats {
          color: rgb(255 255 255 / 80%);
          display: block;
          font-size: 13px;

          @include respond-to('phone') {
            margin-top: 10px;
          }

          &__data {
            display: inline-block;
            margin-right: 5px;

            &--bold {
              font-weight: 500;
              text-transform: uppercase;
            }
          }
        }

        &__title {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 36px;
          line-height: 45px;
          letter-spacing: -0.03em;
          color: #fff;
          margin-top: 30px;

          @include respond-to('phone') {
            margin-top: 10px;
            font-family: 'Work Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -0.03em;
            color: #fff;
          }
        }

        p {
          margin-top: 16px;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #a1a2a2;
          font-family: 'Works Sans', sans-serif;
        }
      }
    }

    &__hub {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 50px;

      @include respond-to('phone') {
        grid-template-columns: 1fr;
        gap: 0;
      }

      &__post {
        margin-bottom: 40px;
        width: 100%;
        overflow: hidden;
        transition: all 0.4s ease-in-out;

        &:hover {
          .blog-page__body__hub__post__image img {
            transform: scale(1.1);
            transition: all 0.4s ease-in-out;
          }
        }

        &__image {
          width: 100%;
          margin-bottom: 20px;

          img {
            width: 100%;
            display: block;
            aspect-ratio: 16 / 9;
            object-fit: cover;
          }
        }

        &__title {
          margin-top: 20px;
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 21px;
          line-height: 30px;
          letter-spacing: -0.03em;
          color: #fff;
        }

        p {
          margin-top: 16px;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #a1a2a2;
          font-family: 'Works Sans', sans-serif;
        }
      }
    }
  }
}

.pagination {
  font-family: 'Work Sans', sans-serif;
  padding-top: 20px;
  padding-bottom: 20px;
  width: fit-content;
  margin: auto;

  li {
    display: inline-grid;
    vertical-align: top;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    color: #fff;

    a {
      color: #fff;

      i {
        color: #fff;
      }
    }
  }

  &__box {
    box-sizing: border-box;
    height: 40px;
    width: 40px;

    &--current {
      height: 40px;
      width: 40px;
      border: 1px solid #676868;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 40px;
      text-align: center;
      margin-right: 7px;
    }

    &__prev {
      img {
        transform: scaleX(-1);
      }
    }

    &__letter {
      line-height: 40px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      text-align: center;
      margin-right: 7px;
    }
  }
}

.call-to-action {
  width: 100%;
  background-color: #efff04;
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;


  @include respond-to('phone') {
    padding: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h3 {
    align-items: center;
    display: grid;
  }

  &__button {
    margin-left: 50px;
    margin-right: 50px;
    background: #0b0a0e;
    padding: 25px;
    display: grid;
    text-align: center;

    @include respond-to('phone') {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

}

.case-studies-body {
  .article__body__banner {
    .article__body__banner__box {
      top: 100px;
    }

    .article__body__banner__box__one {
      margin-top: 43px;
    }
  }
}

.description-seo {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #a1a2a2;
  font-family: 'Works Sans', sans-serif;
  width: 900px;
  margin: auto;

  @include respond-to('phone') {
    width: 100%;
  }

  a {
    color: #efff04;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: 'Space Grotesk', sans-serif;
  }

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: 'Space Grotesk', sans-serif;
  }

  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: 'Space Grotesk', sans-serif;
  }

  p {
    margin-bottom: 20px;
  }
}

.article-padding {
  padding-bottom: 104px !important;

  @include respond-to('phone'){
    padding-bottom: 16px !important;
  }

  h2 {
    scroll-margin-top: 157px !important;
  }

  img {
    margin: auto;

    @include respond-to('phone') {
      width: 100% !important;
      height: fit-content !important;
    }
  }
}

.video-container {
  width: 60%;
  margin: auto !important;
  margin-bottom: 60px !important;

  @include respond-to('phone') {
    width: 100%;
  }
}

.link-to-active {
  &.active {
    text-decoration: underline;
    text-underline-color: #efff04;
  }
}
