.contactSection {
  padding-top: 186px !important;
  padding-bottom: 104px !important;
  background: $surface-primary;
  display: flex;
  justify-content: space-between;
  position: relative;

  @include respond-to('phone_tablet') {
    flex-direction: column-reverse;
    gap: 50px;
    padding: 50px 0 !important;
  }

  @include respond-to('phone') {
    display: block;
  }

  &--contact-page {
    padding-bottom: 186px !important;
    padding-top: 201px !important;

    @include respond-to('phone_tablet') {
      padding-bottom: 50px !important;
      padding-top: 80px !important;
    }

    .contactSection__image {
      top: auto;
      bottom: 0;
      width: 50%;
      object-fit: contain;
      left: 150px;

      @include respond-to('fullhd') {
        left: 180px;
      }

      @include respond-to('tablet') {
        position: static;
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      @include respond-to('phone') {
        position: static;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .contactInfo {
      justify-content: flex-start;
    }
  }

  &__image {
    top: 0;
    left: 0;
    position: absolute;
    height: 446px;
    width: 100%;
    object-fit: cover;

    @include max-wxga {
      height: 40vh;
      position: relative;
    }
  }

  & > div,
  & > form {
    z-index: 1;
  }
}

.contactGrid {
  display: grid;
  grid-template-columns: auto 595px;

  @include respond-to('phone') {
    grid-template-columns: 1fr;
    margin-top: 35px;
    padding: 16px;

  }
}

.contactInfo {
  @include respond-to('phone') {
    margin-bottom: 50px;
  }

  &__title {
    color: $text-primary;
    font-family: 'Space Grotesk', serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    letter-spacing: -0.96px;
    padding-bottom: 62px;
    width: 100%;
    padding-right: 200px;

    @include respond-to('phone') {
      padding-right: 0;
    }

    @include max-hd-plus {
      padding-bottom: 40px;
    }

    @include max-wxga {
      font-size: 40px;
    }

    @media screen and (width <= 419px) {
      font-size: 32px;
      padding-bottom: 32px;
    }
  }

  &__subtitle {
    color: $grey-medium;
    font-family: 'Space Grotesk', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    width: 100%;
  }

  &__list {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;

    li {
    }
  }
}

.contactForm {
  background: $surface-invert;
  width: 595px;

  @include respond-to('phone_tablet') {
    margin-left: 0;
    width: 100%;
  }

  &__inputs {
    padding: 46px 30px;

    @media screen and (width <= 400px) {
      padding: 24px 16px;
    }
  }

  &__title {
    color: $surface-primary;
    font-family: 'Space Grotesk';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.56px;
    padding-bottom: 12px;

  }

  &__subtitle {
    color: $surface-primary;
    font-variant-numeric: ordinal;
    font-feature-settings: 'ss05' on, 'ss04' on, 'ss01' on, 'salt' on, 'ss03' on, 'case' on;
    font-family: 'Work Sans', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.contactLabel {
  .error {
    border: 1px solid red !important;
  }
}

.contactInput {
  border: none;
  width: 100%;
  padding: 24px 10px 25px 30px;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;

  @media screen and (width <= 400px) {
    padding: 18px 8px 18px 24px;
  }

  &::placeholder {
    color: $surface-primary;

    /* body/largeMedium */
    font-family: 'Work Sans', serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    letter-spacing: -0.4px;
  }

  &--top {
    margin-top: 32px;
    border-top: 1px solid $surface-primary;
    border-left: 1px solid $surface-primary;
    border-right: 1px solid $surface-primary;

    @media screen and (width <= 400px) {
      margin-top: 24px;
    }
  }

  &--bottom {
    resize: none;
    height: 154px;
    border: 1px solid $surface-primary;
    margin-bottom: 32px;

    @media screen and (width <= 400px) {
      margin-bottom: 24px;
    }
  }
}

.contactCheckbox {
  display: block;
  position: relative;
  padding-left: 60px;
  cursor: pointer;
  color: $surface-primary;
  font-family: 'Space Grotesk', serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  .error {
    border: 1px solid red !important;
  }

  @include max-tablet {
    padding-left: 45px;
  }

  a {
    color: inherit;
    font-weight: bold;
    position: relative;
    z-index: 2;

    &:visited {
      color: inherit;
    }
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 27px;
    width: 27px;
    background-color: transparent;
    border: 1px solid $surface-primary;
  }

  .checkmark::after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked {
    border: none;
  }

  input:checked ~ .checkmark::after {
    display: block;
  }

  .checkmark::after {
    border: solid $surface-primary;
    border-width: 0 1px 1px 0;
    height: 14px;
    left: 9px;
    top: 3px;
    transform: rotate(45deg);
    width: 7px;
  }
}
