/* General style */
.color-body {
  background-color: #0b0a0e;
}

.new-column {
  padding-top: 75px;
  padding-bottom: 75px;

  @include respond-to('phone') {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__wrapper {
    display: grid;
    justify-content: center;
    text-align: center;
    align-items: center;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
    }

    &__right {
      display: grid;
      justify-content: end;

      @include respond-to('phone') {
        justify-content: center;
      }
    }
  }

  &__title {
    @extend .typography--h2;

    text-align: center;
    color: #13101b;
    margin-bottom: 5px;

    @include respond-to('phone') {
      font-size: 32px;
      line-height: 44px;
      margin-top: 30px;
    }
  }

  &__sub-title {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: center;
    font-feature-settings: 'dnom' on, 'numr' on, 'sinf' on, 'case' on, 'zero' on, 'ss04' on, 'ss05' on, 'ss03' on,
    'ss01' on, 'salt' on, 'ordn' on;
    color: #13101b;

    @include respond-to('phone') {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .details-home__body__button {
    position: relative;
  }
}

.case-studies-body {
  .title-button {
    text-transform: uppercase;
    margin-top: 43px;
    color: #13101b;
  }
}

.title-button {
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  padding: 5px 10px;
  width: fit-content;
  filter: drop-shadow(0 4px 4px rgb(0 0 0 / 25%));
  background-color: #979797;
  margin-bottom: 20px;

  &--white {
    background-color: #f0edeb;
  }
}

.section {
  @include respond-to('phone') {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .container {
    padding: 0 104px !important;
    width: auto !important;
    margin: auto !important;
    height: 100%;

    @include respond-to('fullhd') {
      padding: 0 104px !important;
    }

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }

  //a {
  //  background-color: #fff !important;
  //  padding: 2px;
  //  color: #13101b !important;
  //  text-decoration: underline;
  //  font-weight: bold;
  //}

  &.case-studies-top {
    margin-top: 107px;

    @include respond-to('phone') {
      margin-top: 0;
    }

    .details-home__body__button {
      background-color: transparent !important;
    }

    .article__body__content__toc__li {
      text-align: left;
    }
  }

  .details-home__body__button--dark {
    position: relative !important;
    background-color: #0b0a0e !important;
    height: auto;
    border: none;
    padding: 0;
    align-content: center;
    display: grid;
    width: 100%;

    @include respond-to('phone') {
      margin-bottom: 40px;
    }

    .details-home__body__button__txt {
      align-content: center;
      display: grid;
      border: 2px solid #0b0a0e;
      color: #fff;
      padding: 0;
      font-weight: 700;
      font-size: 20px;

      @include respond-to('phone') {
        font-size: 17px;
      }
    }

    .details-home__body__button__sign {
      background-color: #0b0a0e;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

/* top style */
.new-top {
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 650px;
  padding-bottom: 104px;
  background-position: center top;

  @include respond-to('phone') {
    height: auto;
    min-height: auto;
    padding-bottom: 120px;
    padding-top: 50px;
    margin-bottom: 0;
    background-position: center center;
  }

  &.case-studies-top {
    h1,
    h2,
    p {
      text-align: left !important;
    }

    .container {
      padding: 0 80px !important;

      @include respond-to('fullhd') {
        padding: 0 104px !important;
      }

      @include respond-to('phone') {
        padding: 0 16px !important;
      }
    }

    & > div {
      margin-bottom: 50px;
    }
  }

  &__wrapper {
    display: grid;
    width: 70%;
    height: calc(100% - 100px);
    align-content: center;
    margin: auto;
    align-self: center;
    position: relative;
    top: 68px;
    justify-items: center;

    @include respond-to('phone') {
      width: 100%;
      height: 100%;
    }

    &.case-studies-top {
      grid-template-columns: 1fr 30%;
      grid-gap: 60px;
      width: 100%;

      @include respond-to('phone') {
        width: 100%;
        height: 100%;
        grid-gap: 0;
        grid-template-columns: 1fr;
      }
    }

    .details-home__body__button {
      position: relative;
    }

    .details-home__body__button--services {
      position: relative;
      margin: auto;
      background: #efff04 !important;
      height: 86px;
      border: none;
      padding: 0;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      .details-home__body__button__txt {
        color: #1a1b1c;
        font-weight: 700;
        font-size: 20px;
        display: grid;
        border: none;
        border-right: 2px solid #1a1b1c;
      }
    }
  }

  &__sub-title {
    h1 {
      @extend .typography--h1;

      margin-bottom: 10px;
      color: #fff;
      background: rgb(0 0 0 / 40%);
      margin-top: 80px;
      text-align: center;

    }

    h2 {
      @extend .typography--h2;

      color: #f0edeb;
      margin-bottom: 40px;
      background: rgb(0 0 0 / 40%);
      text-align: center;
    }
  }

  &__text {
    margin-bottom: 50px;

    p {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 30px;
      letter-spacing: -0.02em;
      color: #f0edeb;
      text-align: center;
      background: rgb(0 0 0 / 40%);

      @include respond-to('phone') {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  &__table {
    &__title {
      font-family: 'Space Grotesk', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #979797;
      margin-bottom: 14px;

      @include respond-to('phone') {
        line-height: 20px;
        font-size: 13px;
      }
    }

    .article__body__content__toc__li {
      font-size: 19px;
      line-height: 15px;
      margin-bottom: 10px;
      text-align: center;
    }
  }
}

.new-services {
  background: #f0edeb;
  padding-bottom: 40px;

  @include respond-to('phone') {
  }

  &__title {
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 1;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.05em;
    font-feature-settings: 'dnom' on, 'numr' on, 'sinf' on, 'case' on, 'zero' on, 'ss04' on, 'ss05' on, 'ss03' on,
    'ss01' on, 'salt' on, 'ordn' on;
    color: #fff;
    margin-top: 66px;
    margin-bottom: 100px;
    width: 700px;

    @include respond-to('phone') {
      width: 100%;
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 40px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-gap: 5%;
    padding-bottom: 75px;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
    }

    &__nav {
      cursor: pointer;

      &__link {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        align-items: center;
        letter-spacing: -0.02em;
        color: #14111b;
        padding: 10px 20px;
        width: 100%;
        display: grid;
        grid-template-columns: 17px 1fr;
        grid-gap: 10px;

        &.active-color {
          .hidden-arrow {
            visibility: visible;
          }
        }

        .hidden-arrow {
          visibility: hidden;
        }

        .arrow-list {
          width: 17px;
          display: grid;
          align-content: center;

          img {
            width: 100%;
          }
        }

        .arrow-title {
          width: calc(100% - 32px);
        }
      }
    }

    &__body {
      &__box {
        border: 1px solid #979797;
        padding: 50px;
        width: 100%;
        transition: opacity 1s ease-out;
        display: none;

        &.active {
          display: block;
          animation: fade_in_show 0.8s;
        }

        @keyframes fade_in_show {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        &__image {
          width: 50px;
          align-items: center;
          align-content: flex-start;
          display: grid;

          img {
            width: 100%;
          }
        }

        &__title {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 30px;
          align-items: center;
          letter-spacing: -0.02em;
          color: #0b0a0e;
          margin-top: 30px;
        }

        &__text {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: -0.02em;
          color: #0b0a0e;
          margin-top: 30px;
        }
      }
    }
  }
}

.new-testimonial {
  padding: 104px 0;

  @include respond-to('phone') {
    padding: 54px 0;
  }

  &__top {
    grid-template-columns: 1fr 2fr;
    grid-gap: 32px;
    display: grid;
    margin-bottom: 104px;

    @include respond-to('phone') {
      grid-gap: 0;
      grid-template-columns: 1fr;
      margin-bottom: 54px;
    }

    &__left {
      &__info {
        margin-top: 32px;
        grid-template-columns: 1fr 3fr;
        grid-gap: 32px;
        display: grid;

        @include respond-to('phone') {
          grid-template-columns: 1fr 4fr;
        }

        &__img {
          width: 100%;
          height: 100%;
          display: grid;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include respond-to('phone') {
              height: 72px;
              width: 72px;
            }
          }
        }

        &__data {
          width: 100%;
          height: 100%;
          position: relative;

          &__text {
            position: absolute;
            bottom: 0;

          }

        }

      }
    }

    &__right {
      margin-top: 39px;
    }

    &__info {
      margin-top: 46px;
    }
  }

  &__img {
    width: 100%;

    img {
      width: 100%;
      height: calc(100vh - 107px);
      object-fit: cover;
    }
  }
}

.new-image {
  padding: 104px 0;

  @include respond-to('phone') {
    padding: 54px 0;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    position: relative;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__left {
      display: grid;
      grid-template-columns: 2fr 1fr;

      @include respond-to('phone') {
        width: 100%;
        grid-template-columns: 1fr;
      }

      &__sticky {
        position: sticky;
        top: 107px;
      }

      h2 {
        margin-bottom: 10px;
      }
    }

    &__right {
      column-count: 2;
      column-gap: 1em;
      column-gap: 32px;

      @include respond-to('phone') {
        display: grid;
        column-count: 1;
        margin-top: 54px;
        grid-template-columns: 1fr;
      }

      &__box {
        page-break-inside: avoid;
        break-inside: avoid;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 104px;

        @include respond-to('phone') {
          margin-bottom: 54px;

        }

        &__img {
          margin-bottom: 86px;
          width: 60%;

          img {
            width: 100%;
            height: 100%;
          }

          @include respond-to('phone') {
            margin-bottom: 44px;
          }
        }

        &__title {
          margin-bottom: 27px;
        }
      }
    }

  }
}

@mixin transition($property, $duration, $timing) {
  transition: $property $duration $timing;
}

.new-faq {
  padding-bottom: 208px;

  @include respond-to('phone') {
    padding-bottom: 104px;
  }

  .details-home__body__button {
    text-align: center;

    @include respond-to('phone') {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 32px;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }

    &__left {
      &__no-background {
        background-color: transparent !important;
      }

      &__photo {
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-gap: 32px;
        padding-top: 52px;
        padding-bottom: 52px;
        align-items: end;

        @include respond-to('phone') {
          padding-top: 32px;
          padding-bottom: 32px;
        }

        &__img {
          display: grid;
          align-items: self-end;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &__name {
          display: grid;
          align-items: self-end;
        }
      }

      &__image {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;

        img {
          width: 100%;
        }
      }

      &__box {
        padding: 40px;
        background-color: #efff04;
        width: 100%;

        @include respond-to('phone') {
          padding: 20px;
        }

        &__image {
          width: 50px;
          height: 50px;
          margin-bottom: 20px;
        }

        &__title {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 50px;
          align-items: center;
          letter-spacing: -0.04em;
          color: #13101b;
          margin-bottom: 30px;
        }

        &__text {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #13101b;
          margin-bottom: 40px;
        }
      }

      .sticky-banner {
        background-color: $text-primary;
        border: 1px solid $surface-primary;
        width: 100%;
        position: sticky;
        top: 127px;

        @include respond-to('phone') {
          transform: none;
          margin-bottom: 20px;
          z-index: 1000;
        }

        @include respond-to('tablet') {
          width: 250px;
        }

        @include respond-to('desktop') {
          width: 350px;
        }

        @include respond-to('hd') {
          width: 493px;
        }

        .title-description {
          padding: 40px 30px;
          border-bottom: 1px solid $surface-primary;

          @include respond-to('phone') {
            padding: 32px 24px;
          }

          @include respond-to('tablet') {
            padding: 10px 5px;
          }

          @include respond-to('desktop') {
            padding: 30px 20px;
          }
        }

        .yellow-button-big {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $brand-yellow !important;
          border: none;

          @include respond-to('phone') {
            padding: 0 24px;
          }

          .btn-text {
            text-align: left;
            padding: 20px;

            @include respond-to('phone') {
              padding: 20px 0;
            }

            @include respond-to('tablet') {
              padding: 24px;
            }
          }

          .btn-img {
            width: 32px;
            padding: 9px;
            margin-right: 20px;

            @include respond-to('phone') {
              margin-right: 0;
            }
          }
        }
      }
    }

    &__right {
      @include respond-to('phone') {
        margin-top: 0;
      }

      h2 {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .faq-padding-bottom {
        padding-bottom: 72px;
      }

      .item {
        width: 100%;

        &.typography--white {
          input[type='checkbox'] {
            &:checked {
              & + label {
                color: #fff !important;
                border-bottom: 1px solid #13101b !important;

                &::after {
                  background: url("img/arrow-down-skew.svg") no-repeat;
                  height: 24px;
                  width: 24px;

                }

                & + div {
                  border-bottom: 1px solid #353538 !important;

                  ul {
                    li {
                      color: #fff !important;
                    }
                  }
                }
              }
            }
          }

          label {
            border-bottom: 1px solid #353538 !important;

            &::after {
              color: #fff !important;
            }

            &:hover {
              color: #fff !important;

              &::after {
                color: #fff !important;
              }
            }
          }
        }

        input[type='checkbox'] {
          display: none;

          &:checked {
            & + label {
              background-color: transparent;
              color: #13101b;
              border: none;

              &::after {
                content: ' ';
                background: url("img/arrow-down-skew-black.svg");
                color: #13101b;
              }


              & + div {
                display: block;
                border-bottom: 1px solid #13101b;
                padding-top: 0;

                ul {
                  list-style: inside;
                  margin-top: 15px;

                  li {
                    color: #13101b;
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }

        label {
          display: block;
          width: 100%;
          position: relative;
          padding: 40px 0;
          background-color: transparent;
          cursor: pointer;
          webkit-touch-callout: none;
          user-select: none;
          border-bottom: 1px solid #13101b;

          @include respond-to('phone') {
            padding-left: 0;
            font-weight: 500;
            font-size: 20px;
            padding-right: 50px;
          }

          &::after {
            content: ' ';
            position: absolute;
            right: 10px;
            color: #13101b;
            font-size: 36px;
            font-weight: 500;

            @include respond-to('phone') {
              top: 41px;
            }
          }

          &:hover {
            background-color: transparent;
            color: #13101b;

            @include transition(background-color, 100ms, linear);

            &::after {
              color: #13101b;
            }
          }
        }

        div {
          width: 100%;
          display: none;
          border-top: none;
          padding: 40px 0;

          @include respond-to('phone') {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
          }

          p, aside {
            opacity: 1 !important;

          }
        }
      }
    }
  }

  &__opacity {
    p {
      opacity: 1 !important;
    }
  }
}

.new-approach {
  padding-bottom: 104px;

  @include respond-to('phone') {
    margin-top: 0;
    padding-bottom: 40px;
  }

  .new-column__title {
    color: #fff;
  }

  .new-column__sub-title {
    color: #fff;
  }

  &__wrapper {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 100px;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
      grid-gap: 50px;
      margin-top: 60px;
    }
  }

  &__box {
    padding: 50px;
    background-color: #211e28;

    @include respond-to('phone') {
      padding: 20px;
      width: 100%;
    }

    &__header {
      display: grid;
      grid-template-columns: 120px 1fr;
      grid-gap: 30px;

      &__image {
        width: 120px;
        height: 120px;
        background-size: cover;
        border-radius: 2px;
        position: relative;

        &__icon {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 20px;
          height: 20px;
          background-color: #efff04;
          display: grid;
          place-items: center center;
        }
      }

      &__info {
        &__name {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 25px;
          line-height: 30px;
          letter-spacing: -0.05em;
          color: #fff;
          margin-bottom: 23px;
        }

        .full-width {
          width: 100%;
          display: block;
        }

        &__details {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: 1;
          font-size: 19px;
          line-height: 24px;
          color: #f0edeb;

          > div {
            display: inline-block;
          }

          .label {
            font-weight: 700;
          }
        }

        &__rawHTML {
          margin-top: 50px;
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #f0edeb;

          b,
          strong {
            font-weight: 700;
          }

          h2,
          h3 {
            font-family: 'Space Grotesk', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 25px;
            line-height: 30px;
            letter-spacing: -0.05em;
            color: #fff;
            margin-bottom: 18px;
            margin-top: 50px;
          }

          ul {
            list-style-type: disc;

            li {
              font-family: 'Work Sans', sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 19px;
              line-height: 32px;
              letter-spacing: -0.02em;
              color: #f0edeb;
              padding-left: 10px;
              margin-left: 25px;
            }
          }
        }
      }
    }
  }
}

.technologies-used {
  padding-top: 60px;
  padding-bottom: 60px;

  .container {
    padding: 0 104px !important;
    width: auto !important;
    margin: auto !important;

    @include respond-to('fullhd') {
      padding: 0 104px !important;
    }

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 60px;

    @include respond-to('phone') {
      grid-template-columns: 1fr;
    }

    &__item {
      display: grid;
      height: 90px;
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      grid-template-columns: 95px 1fr;
      align-content: center;
      grid-gap: 0;
      border: 1px solid transparent;
      border-bottom: 1px solid #f0edeb;

      &:hover {
        border: 1px solid #13101b;
        box-sizing: border-box;
        text-decoration: none !important;
      }

      &--border-top {
        border-top: 1px solid #f0edeb;

        &--mobile {
          @include respond-to('phone') {
            border-top: none !important;
          }
        }
      }

      &:last-of-type {
        @include respond-to('phone') {
          border-bottom: 1px solid #f0edeb;
        }
      }

      &--image {
        height: 60px;
        display: grid;
        justify-content: center;
        justify-items: center;

        img {
          height: 58px;
          margin-top: 1px;
        }
      }

      &--title {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        line-height: 30px;
        letter-spacing: -0.02em;
        display: grid;
        justify-items: left;
        justify-content: left;
        color: #13101b;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}

.new-call {
  &__caption {
    margin-bottom: 8px;
  }

  @include respond-to('phone') {
    height: 100vh;
  }

  &__height-max {
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    &__full {
      height: 100vh;
    }

    .new-call__wrapper--black, .new-call__wrapper--white, .new-call__wrapper--yellow {
      background: transparent !important;
    }

  }

  &__wrapper {
    @include respond-to('phone') {
      .container {
        padding-bottom: 30px;
        padding-top: 30px;
      }
    }

    &--yellow {
      background: #efff04;
    }

    &--white {
      background: #fff;
    }

    &--black {
      background: #0b0a0e;
    }
  }

  &__body {
    display: grid;
    grid-gap: 32px;
    max-width: 40%;
    padding: 104px 0;
    align-content: space-between;

    @include respond-to('phone') {
      padding: 54px 0;
      grid-gap: 54px;
      max-width: 100%;
    }

    @include respond-to('phone') {
      grid-template-columns: 1fr;
    }


    &__left {
      max-width: 100%;

      &__text {
        margin-bottom: 40px;
      }

      &__button {
        position: relative;
        display: grid;
        height: 72px;
        grid-template-columns: 1fr 8fr;
        grid-gap: 32px;
        max-width: 75%;

        @include respond-to('phone') {
          max-width: 100%;
          grid-template-columns: 1fr;
          height: auto;
          grid-gap: 0;
        }

        &__click {
          height: 72px;
          border: 1px solid #0b0a0e;

          a {
            padding: 20px 24px 24px;
            display: block;
            width: 100%;
            height: 100%;

            @include respond-to('phone') {
              padding: 20px 10px 10px;
            }

            img {
              position: absolute;
              top: 35px;
              right: 24px;
            }
          }
        }
      }
    }

    &__right {
      height: 100%;
      width: 100%;
      position: relative;

      @include respond-to('phone') {
        display: none;
      }


      img {
        height: 100%;
        right: -104px;
        position: absolute;
        width: calc(100% + 104px);
      }
    }
  }
}

.call-to-action {
  a {
    background-color: transparent !important;
  }
}

.widgets_wrapper {
  @include respond-to('phone') {
    max-width: 100% !important;
  }
}
