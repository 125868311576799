.script-top {
  height: 100vh;
  width: 100%;
  background-color: #21211f;
  position: relative;
  overflow: hidden;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;

  @include respond-to('phone') {
    height: 100%;
  }

  .container {
    padding: 0 104px !important;
    margin: auto !important;
    width: auto !important;

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }

  video {
    position: absolute;
    inset: 0;

    //width: 100% !important;
    height: auto !important;
    transition: 1s all;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  &__box {
    position: relative;
    width: 500px;
    display: inline-flex;
    vertical-align: top;
    height: 100vh;
    align-content: center;
    flex-wrap: wrap;
    z-index: 8888;

    @include respond-to('fullhd') {
      width: 750px;
    }

    @include respond-to('phone') {
      width: 100%;
      height: 100%;
      padding-top: 140px;
      padding-bottom: 80px;
    }

    @include respond-to('tablet') {
      //top: 230px;
    }

    &__topic {
      @include style-element('h1');

      position: relative;
      z-index: 3;
      color: #fff !important;

      @include respond-to('phone') {
        font-weight: 600 !important;
        letter-spacing: -0.47px !important;
        word-break: break-word !important;
      }

      @include respond-to('fullhd') {
        font-size: 64px !important;
        line-height: 80px !important;
      }

      @include respond-to('small_mobile') {
        font-size: 40px;
        line-height: 50px;
      }

      @include respond-to('small_mobile_height') {
        font-size: 30px !important;
        line-height: 40px !important;
      }
    }

    &__type {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.17px;
      line-height: 32px;
      color: #fff;
      margin-top: 44px;

      @include respond-to('small_mobile_height') {
        font-size: 16px;
        line-height: 26px;
        margin-top: 30px;
      }

      @include respond-to('fullhd') {
        font-size: 26px;
        line-height: 40px;
      }

      h2 {
        display: inline;
        vertical-align: top;

        &:hover {
          cursor: pointer;
          color: #fff;
        }
      }
    }

    .action-arrow {
      margin-top: 64px;
      border: 1px solid #e4a044;
      border-left: none;
      padding-top: 8px;
      padding-bottom: 8px;
      width: fit-content;
      z-index: 9;
      padding-right: 20px;

      @include respond-to('phone') {
      }

      @include respond-to('tablet') {
        margin-top: 44px;
      }

      &__describe--cream {
        color: #e4a044;
      }
    }
  }

  &__video {
    width: calc(100% - 150px);
    height: 670px;
    background-color: rgb(39 41 42 / 30%);
    position: absolute;
    background-position: center;
    background-size: cover;
    bottom: 0;
    z-index: 2;

    @include respond-to('hd') {
      width: calc(100% - 300px);
    }

    @include respond-to('tablet') {
      width: calc(100% - 60px);
      height: 450px;
    }

    @include respond-to('phone') {
      width: 100%;
      height: 397px;
      left: 0;
    }

    &__box {
      position: absolute;
      top: 420px;
      padding-bottom: 50px;

      @include respond-to('phone') {
        top: 75px;
        width: 100%;
      }

      @include respond-to('tablet') {
        top: 260px;
      }

      &__left {
        display: inline-block;
        vertical-align: top;
        margin-left: 100px;
        padding-top: 50px;

        @include respond-to('phone') {
          margin-left: 0;
          padding-left: 15px;
          width: 100%;
          display: none;
        }

        img {
        }
      }

      &__right {
        display: inline-block;
        vertical-align: top;
        margin-left: 30px;

        @include respond-to('phone') {
          margin-left: 0;
          padding-left: 30px;
          padding-right: 30px;
          display: block;
          vertical-align: top;
          width: 100%;
          margin-top: 100px;
        }

        &__category {
          font-size: 12px;
          font-weight: 600;
          line-height: 30px;
          text-transform: uppercase;
          color: #fff;
        }

        &__lead {
          font-size: 25px;
          font-weight: 600;
          letter-spacing: -0.59px;
          line-height: 35px;
          width: 440px;
          color: #fff;
          margin-top: 15px;

          @include respond-to('phone') {
            width: 100%;
          }
        }
      }
    }
  }

  .webgl {
    canvas {
      position: absolute;
      right: 0;
      top: 0;
      width: 100% !important;
      height: 100%;
      z-index: 1;
    }
  }
}
