.contact {
  position: relative;
  padding: 90px 150px;

  @include respond-to('fullhd') {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  @include respond-to('hd') {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  @include respond-to('tablet') {
    padding: 30px;
  }

  @include respond-to('phone') {
    display: block;
    padding: 30px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 140px;
    padding: 60px;

    @include respond-to('phone') {
      grid-template-columns: 100%;
      display: block;
      padding: 30px;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    z-index: 1;

    .close::before,
    .close::after {
      background-color: #333 !important;
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__opinion {
      h2 {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        font-feature-settings: 'dnom' on, 'numr' on, 'sinf' on, 'case' on, 'zero' on, 'ss04' on, 'ss05' on, 'ss03' on,
          'ss01' on, 'salt' on, 'ordn' on;
        color: #fff;
        margin-bottom: 40px;

        @include respond-to('phone') {
          margin-bottom: 10px;
        }
      }

      p {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #fff;

        @include respond-to('phone') {
          margin-bottom: 50px;
        }
      }
    }

    .navbar__href__logo {
      position: relative !important;
      top: 0;
      width: auto !important;
    }

    @include respond-to('tablet') {
      padding: 50px 40px;
    }

    @include respond-to('phone') {
      background-size: cover !important;
      display: block;
    }
  }

  &__form {
    position: relative;

    //padding: 60px 50px 60px;

    @include respond-to('fullhd') {
      //padding: 110px 120px 110px;
    }

    @include respond-to('tablet') {
      //padding: 85px 80px 85px;
    }

    @include respond-to('phone') {
      //padding: 50px 30px 50px;
    }

    @include respond-to('desktop') {
      display: block;
    }

    .details-home__body__button {
      position: relative;
    }

    .jsContactFormContainer,
    .jsNewsletterFormContainer {
      .rodo {
        padding: 40px;
        font-size: 12px;
        color: #fff;
        letter-spacing: normal;
        line-height: normal;

        .show-more-rodo.active a {
          color: #efff04 !important;
        }
      }
    }

    .details-home__body__button--services {
      position: relative;
      margin: auto;
      background: #efff04 !important;
      height: 86px;
      border: none;
      width: 100%;
      margin-top: 20px;
      cursor: pointer;

      .details-home__body__button__txt {
        color: #1a1b1c;
        border-right: 2px solid #1a1b1c;
      }
    }

    &__container {
      padding-top: 25px;

      &__checkbox {
        position: absolute;
        top: 5px;
        left: 0;
        height: 14px;
        width: 14px;
        background-color: #fff;
        border: 1px solid #000;

        &::before {
          position: absolute;
          content: '';
          left: 4px;
          top: 1px;
          display: none;
          width: 4px;
          height: 7px;
          border: solid #000;
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);
        }

        &::after {
          position: absolute;
          content: '';
          display: none;
        }
      }

      &__label {
        position: relative;
        display: block;
        width: 100%;
        color: #000;
        font-size: 12px;
        font-weight: 400;
        line-height: 25px;

        &--bold {
          font-weight: 500;
        }

        &--privacy {
          color: #fff;
          font-size: 15px;
          line-height: 25px;
          letter-spacing: -0.59px;
          font-weight: 400;
          padding-left: 30px;
          margin-top: 35px;
          cursor: pointer;

          @include respond-to('phone') {
            font-size: 13px;
          }

          input {
            position: absolute;
            top: -7px;
            z-index: 9999;
            left: 0;
            opacity: 0;
            cursor: pointer;
            width: 100%;
            height: 100%;
          }

          input:checked ~ span::after,
          input:checked ~ span::before {
            display: block;
          }
        }
      }

      &__textarea {
        resize: vertical;
        font-family: 'Work Sans', sans-serif;
        min-height: 110px;
      }

      &__input,
      &__textarea {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 2px;
        background-color: transparent;
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        color: #fff;
        letter-spacing: -0.02em;
        border: none;
        border-bottom: 1px solid #909090;
        margin-bottom: 20px;
        outline: none;

        &:focus {
          outline: none;
        }

        &::input-placeholder,
        &::-webkit-textarea-placeholder {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 32px;
          color: #fff;

          /* identical to box height, or 178% */

          letter-spacing: -0.02em;
        }
      }

      &__button {
        display: grid;
        align-content: center;
        width: 100%;
        padding: 10px;
        margin-top: 30px;
        border-radius: 2px;
        background-color: #0b0a0e;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
        border: 1px solid #0b0a0e;
        cursor: pointer;
        appearance: none;
        opacity: 1;
      }
    }
  }
}

.error {
  border-bottom: 1px solid red !important;
}

.error_rodo {
  color: red;
}

.testimonial {
  padding: 60px 50px;

  @include respond-to('phone') {
    padding: 50px 30px;
  }

  @include respond-to('fullhd') {
    padding: 70px 100px 110px;
  }

  .rich-text {
    padding: 0;

    p {
      padding: 0;
    }
  }

  &__text {
    color: #fff;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -0.59px;
    padding-bottom: 50px;
    max-width: 500px;

    @include respond-to('tablet') {
      font-size: 20px;
      line-height: 28px;
      padding-bottom: 30px;
    }
  }

  &__person {
    display: flex;
    flex-direction: row;

    &__image {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 20px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        display: block;
      }

      span:first-of-type {
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
      }

      span:last-of-type {
        color: rgb(255 255 255 / 70%);
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}

.jsContactFeedbackContainer,
.jsNewsletterFeedbackContainer {
  display: none;

  img {
    width: 100%;
  }
}
