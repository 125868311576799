.buttonBig {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  position: relative;
  font-family: 'Space Grotesk', serif;
  font-size: 20px;
  font-weight: 500;

  &::after {
    content: '';
    display: block;
    width: 13px;
    height: 13px;
    background-size: 100% 100%;
    transition: 0.2s;
  }

  &:hover {
    cursor: pointer;

    &::after {
      transition: 0.2s;
      transform: rotate(-45deg);
    }
  }

  &--primary {
    //border: 1px solid $surface-primary;
    background: $brand-yellow;
    color: $surface-primary !important;

    &::after {
      background-image: url('img/arrow.svg');
    }
  }

  &--secondary {
    background: var(--surface-brand, $surface-primary);
    border: none;
    color: white !important;

    &::after {
      background-image: url('img/arrow-white.svg');
    }
  }

  &--caseStudy {
    font-weight: 400;
    font-size: 16px;

    &::after {
      display: none;
    }
  }

  &--caseStudyActive {
    background: #f5f6f7 !important;
    color: $surface-primary !important;
  }
}

.buttonSmall {
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-yellow;
  font-weight: 500;
  font-size: 16px;
  color: $surface-primary;
  padding: 9px 12px !important;
  gap: 11px;

  &::after {
    content: '';
    display: block;
    width: 13px;
    height: 13px;
    background-size: 100% 100%;
    transition: 0.2s;
  }

  &:hover {
    cursor: pointer;

    &::after {
      transition: 0.2s;
      transform: rotate(-45deg);
    }
  }

  &--primary {
    border: 1px solid $surface-primary;
    background: $brand-yellow;
    color: $surface-primary !important;

    &::after {
      background-image: url('img/arrow.svg');
    }
  }

  &--secondary {
    background: var(--surface-brand, $surface-primary);
    border: none;
    color: white !important;

    &::after {
      background-image: url('img/arrow-white.svg');
    }
  }

  &--caseStudy {
    font-weight: 400;
    font-size: 16px;

    &::after {
      display: none;
    }
  }

  &--caseStudyActive {
    background: #f5f6f7 !important;
    color: $surface-primary !important;
  }
}


