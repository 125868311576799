html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  overflow: auto;
}

.overlay-dark {
  background: #0b0a0e;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 890;
  visibility: hidden;
  transition: 0.3s all;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  vertical-align: baseline;
  text-rendering: optimizelegibility;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  margin: 0;
  height: 100%;
}

html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;

}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  padding: 0 50px;
  margin: auto;

  @include respond-to('hd') {
    padding: 0 104px;
  }

  @include respond-to('tablet') {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  @include respond-to('phone') {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  @include respond-to('max_height') {
    margin: auto;
    width: 1920px !important;
  }
}

.container-menu {
  padding: 0 50px;
}

.action-arrow {
  cursor: pointer;
  transition: all 0.4s;
  position: relative;
  vertical-align: top;
  z-index: 999;
  display: grid;
  width: fit-content;
  grid-template-columns: 24px 1fr;
  align-items: center;
  column-gap: 1px;
  border: 2px solid #13101b;
  background-color: #13101b;

  &:hover {
    .action-arrow__arrow img {
      transform: rotate(-45deg);
      transition: all 0.3s;
    }

    .action-arrow__describe--cream {
      background: #efff04;
      color: #13101b;
    }
  }

  @include respond-to('phone') {
    z-index: 999;
  }

  &__arrow {
    background-size: contain;
    width: 24px;
    height: 24px;
    display: inline-grid;
    background-color: #efff04;
    vertical-align: top;
    place-items: center center;

    img {
      width: 8px;
      height: 8px;
    }

    @include respond-to('fullhd') {
      font-size: 14px;
    }
  }

  &__describe {
    display: inline-block;
    vertical-align: top;
    transition: all 0.4s;
    background-color: #13101b;
    height: 100%;
    color: #efff04;

    &--cream {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      place-items: center center;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      color: #efff04;
      align-content: center;
      display: grid;
      padding-left: 10px;
      padding-right: 10px;

      @include respond-to('fullhd') {
        font-size: 14px;
      }
    }
  }
}

.container-main {
  padding: 0 50px;

  @include respond-to('hd') {
    padding: 0 104px;
  }

  @include respond-to('tablet') {
    width: 90%;
  }

  @include respond-to('phone') {
    width: calc(100% - 70px);
    padding: 0;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.body .formWrap p,
body .formButtonWrap p,
body .formWrap h4,
body .formButtonWrap h4 {
  color: red !important;
}

.marginTop100 {
  margin-top: 100px;

  @include respond-to('phone') {
    margin-top: 30px;
  }
}

.marginTop35 {
  margin-top: 35px;
}

.padding-top-bot-25 {
  padding-top: 80px;
  padding-bottom: 80px;

  @include respond-to('phone') {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

.version {
  display: inline-block;
  vertical-align: top;
  float: left;
  margin-top: 6px;
  margin-right: 7px;
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
}

.info-box {
  width: 100%;
  height: 60px;
  position: absolute;
  top: calc(100vh - 60px);
  background: #31312f;
  color: #fff;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 15px;
  z-index: 5;

  a {
    color: #e4a044;
  }
}

.block-body_full_box {
  img {
    width: 80%;
    margin-left: 10%;
    height: 100%;
    float: left;
  }
}

.no-mobile {
  @include respond-to('phone') {
    display: none;
  }
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}

.close:hover {
  opacity: 1;
}

.close::before,
.close::after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;

  @include respond-to('phone') {
    background-color: #fff;
  }
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}

.mask-top {
  @include respond-to('phone') {
    height: 100vh;
    display: grid;
    position: absolute !important;
    align-self: center;
  }
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.logo-absolute {
  position: fixed !important;
}

.fit-container {
  @include respond-to('phone') {
    padding: 0 !important;
  }
}

.href_link {
  cursor: pointer;
}

.margin {
  &__top {
    &--none {
      padding-top: 0 !important;
    }

    &--small {
      padding-top: 70px !important;

      @include respond-to('fullhd') {
        padding-top: 100px !important;
      }

      @include respond-to('tablet') {
        padding-top: 60px !important;
      }

      @include respond-to('phone') {
        padding-top: 30px !important;
      }
    }

    &--big {
      padding-top: 100px !important;

      @include respond-to('fullhd') {
        padding-top: 160px !important;
      }

      @include respond-to('tablet') {
        padding-top: 90px !important;
      }

      @include respond-to('phone') {
        padding-top: 50px !important;
      }
    }

    &--huge {
      padding-top: 160px !important;

      @include respond-to('fullhd') {
        padding-top: 160px !important;
      }

      @include respond-to('tablet') {
        padding-top: 150px !important;
      }

      @include respond-to('phone') {
        padding-top: 110px !important;
      }
    }
  }

  &__bottom {
    &--none {
      padding-bottom: 0 !important;
    }

    &--small {
      padding-bottom: 70px !important;

      @include respond-to('fullhd') {
        padding-bottom: 100px !important;
      }

      @include respond-to('tablet') {
        padding-bottom: 60px !important;
      }

      @include respond-to('phone') {
        padding-bottom: 30px !important;
      }
    }

    &--big {
      padding-bottom: 100px !important;

      @include respond-to('fullhd') {
        padding-bottom: 160px !important;
      }

      @include respond-to('tablet') {
        padding-bottom: 90px !important;
      }

      @include respond-to('phone') {
        padding-bottom: 50px !important;
      }
    }

    &--huge {
      padding-bottom: 160px !important;

      @include respond-to('fullhd') {
        padding-bottom: 160px !important;
      }

      @include respond-to('tablet') {
        padding-bottom: 150px !important;
      }

      @include respond-to('phone') {
        padding-bottom: 110px !important;
      }
    }
  }
}

.fade {
  animation-name: fade;
  animation-duration: 2s;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.margin-top-107 {
  margin-top: 107px;

  @include respond-to('phone') {
    margin-top: 100px;
  }
}

.only-mobile {
  display: none;

  @include respond-to('phone') {
    display: block;
  }
}

.status-404 {
  font-family: 'Work Sans', sans-serif;

  &__number {
    width: 100%;
    text-align: center;
    margin-top: 120px;
    font-size: 160px;
    font-weight: 600;
    line-height: normal;
  }

  &__h1 {
    text-align: center;
    line-height: normal;
  }

  p {
    text-align: center;
    line-height: normal;
    padding-bottom: 120px;
  }
}

#progress-container {
  position: fixed;
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
  z-index: 1000;
  background: transparent;
  transition: all ease-out 0.2s;
}

#progress-container.ready {
  transform: translateY(-3px);
}

#progress-bar {
  display: block;
  width: 0;
  height: 3px;
  background: #efff04;
}

.container-left-zero {
  padding-left: 0 !important;

  @include respond-to('phone') {
    padding-right: 0 !important;
  }

  @include respond-to('max_height') {
    margin: auto;
    width: 1920px !important;
  }
}

.container-only-mobile {
  padding: 0 !important;

  @include respond-to('phone') {
    padding: 0 16px !important;
  }
}

.background-black {
  background: #0b0a0e !important;
}

.background-white {
  background: #fff !important;
}

.background-yellow {
  background: #efff04 !important;
}

.details__body a {
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.arrow-with-date {
  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 10px;
  align-items: center;

  &__arrow {
    background: url('img/arrow-grey.svg');
    width: 10px;
    height: 10px;
  }

  &__title {
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #979797;
  }

  &__info {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
    color: #f0edeb;
    margin-bottom: 20px;
  }
}

.case-studies-margin-bottom {
  margin-bottom: 80px;
}

.contact-menu {
  margin-top: 5px;

  @include respond-to('phone') {
    height: 36px !important;
    margin-top: 15px !important;
  }
}

.rodo {
  font-size: 12px;
  padding-bottom: 30px;

  &--ebook {
    margin-top: 20px;
    font-size: 11px;
    line-height: normal;

    .show-more-rodo a {
      color: #efff04 !important;
    }
  }

  .hide {
    display: none;
  }
}

.show-more-rodo {
  margin-top: 20px;
  display: none;
  line-height: normal;

  &.active {
    display: block;
  }

  a {
    font-weight: bold;
    color: #13101b;
  }
}

.show-more-click {
  cursor: pointer;
  font-weight: bold;
}

.rodo {
  font-size: 12px;
  padding-bottom: 30px;
}

.show-more-rodo {
  margin-top: 20px;
  display: none;

  &.active {
    display: block;
  }

  a {
    font-weight: bold;
    color: #13101b;
  }
}

.show-more-click {
  cursor: pointer;
  font-weight: bold;
}

.lazy {
  content-visibility: auto;
}

html.sr .load-hidden {
  visibility: hidden;
}

.black-background {
  background: #0b0a0e;
}

.padding-top-bottom-100 {
  margin-top: 135px;
  margin-bottom: 50px;

  @include respond-to('phone') {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.padding-button {
  @include respond-to('phone') {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}


.right-items-boxes {
  margin-top: 50px;

  @include respond-to('phone') {
    width: 100%;
  }

  a {
    color: #fff !important;
  }
}

.no-mobile {
  @include respond-to('phone') {
    visibility: hidden !important;
    display: none !important;
  }
}

.no-desktop {
  visibility: hidden !important;
  display: none !important;

  @include respond-to('phone') {
    visibility: visible !important;
    display: flex !important;
  }
}


.hover-zoom {
  overflow: hidden;
  height: 100%;
  transition: transform .5s ease;

  img {
    transition: transform .5s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}

body {
  visibility: hidden;
}
