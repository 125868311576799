
.screen-scroll {
  position: relative;

  &__pages {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;


    &__page {
      padding-top: 124px;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center;

      @include respond-to('phone') {
        background-position: left;
      }


      &__tags {
        &__tag {
          padding: 3px 8px;
          display: inline-block;
          margin-right: 23px;
          border: 1px solid #353538;
        }
      }

      &__title {
        margin-top: 32px;
        margin-bottom: 32px;
        width: 50%;

         @include respond-to('phone') {
           width: 100%;
         }
      }

      &__description {
        width: 30%;

         @include respond-to('phone') {
           width: 100%;
         }
      }

      &__action {
       position: absolute;
        right: 32px;
        bottom: 32px;
        width: 25%;

         @include respond-to('phone') {
          width: calc(100% - 24px);
           left: 12px;
           right: 12px;

         }

        &__box {
          &__top {
            display: grid;
            grid-template-columns: 1fr 65px;
            background: #efff04;

            &__logo {
              padding: 15px 30px;
              height: 65px;
              display: grid;
              align-items: center;

            }

            &__arrow {
              background: #0B0A0E url("img/arrow-yellow-up.png") no-repeat center center;
              width: 65px;
              height: 65px;
            }
          }

          &__title {
            width: 100%;
            display: grid;
            padding: 30px 0 30px 30px;
            color: #0B0A0E;
            font-family: "Space Grotesk", sans-serif;
            font-size: 64px;
            font-style: normal;
            font-weight: 400;
            line-height: 75.28px; /* 117.625% */
            letter-spacing: -1.92px;
            background: #efff04;
          }

          &__description {
            padding: 10px 30px 30px;
            background: #efff04;
          }

          &__link  {
            margin-top: 32px;
            background: #fff;
            padding: 24px;
          }

        }

      }


    }
  }

}
