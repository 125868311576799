.career {
  &__background {
    height: 100vh;
    background-color: #222;
    width: 100%;
    background-size: cover;
    background-position: right;

    @include respond-to('phone') {
      background-position: center;
      height: auto;
      padding-bottom: 40px;
    }

    @include respond-to('tablet') {
      height: auto;

    }
  }

  &__left {
    width: 500px;
    position: relative;

    //height: 100vh;
    align-items: center;
    display: flex;
    height: 100vh;
    align-content: center;
    flex-wrap: wrap;
    z-index: 887;

    a {
      width: 100%;
    }

    &--case {
      height: calc(100vh - 120px);

      @include respond-to('phone') {
        height: calc(100vh - 150px) !important;
        align-content: flex-end;
      }
    }

    @include respond-to('fullhd') {
      width: 700px;
    }

    @include respond-to('phone') {
      width: 100%;
      height: 100%;
    }

    @include respond-to('tablet') {
      position: absolute;
    }

    &__topic {
      font-size: 45px;
      font-weight: 400;
      font-family: 'Space Grotesk', sans-serif;
      letter-spacing: -0.47px;
      line-height: 60px;
      color: #fff;
      word-break: break-word;
      padding-bottom: 40px;

      @include respond-to('phone') {
        line-height: 42px;
        padding-bottom: 30px;
        font-size: 33px;
      }

      @include respond-to('tablet') {
        font-size: 35px;
        line-height: normal;
      }

      @include respond-to('fullhd') {
        font-size: 64px !important;
        line-height: 80px !important;
      }

      &__padding {
        padding-bottom: 30px;
      }
    }

    &__category {
      color: #e4a044;
      font-size: 14px;
      font-weight: 600;
      line-height: 30px;
      margin-top: 0;
      width: 100%;
      padding-top: 0;
      padding-bottom: 30px;

      @include respond-to('fullhd') {
        font-size: 16px;
      }

      @include respond-to('phone') {
        padding-bottom: 15px;
      }
    }

    &__lead {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.17px;
      line-height: 32px;
      color: #fff;

      @include respond-to('fullhd') {
        font-size: 26px;
        line-height: 40px;
      }

      @include respond-to('phone') {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .action-arrow {
      margin-top: 64px;
      border: 1px solid #e4a044;
      border-left: none;
      padding-top: 8px;
      padding-bottom: 8px;
      width: fit-content;
      z-index: 9;
      padding-right: 20px;

      @include respond-to('phone') {
        margin-top: 40px;
      }

      @include respond-to('tablet') {
        margin-top: 44px;
      }

      &__describe--cream {
        color: #e4a044;
      }
    }
  }

  .container-big {
    margin: auto;
    width: 1030px;

    @include respond-to('phone_tablet') {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.simple-text-box {
  @include respond-to('phone') {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.career__background {
  .container {
    padding: 0 50px;
    margin: auto;
    width: auto;

    @include respond-to('hd') {
      padding: 0 104px;
      width: auto;
    }

    @include respond-to('tablet') {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    @include respond-to('phone') {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .case__video {
    @include respond-to('phone') {
      display: none;
    }
  }
}

.career-main {
  background: #ffebc8;

  .section-info {
    padding-top: 120px;
    padding-bottom: 120px;

    @include respond-to('phone') {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  &__video {
    width: 100%;
    height: 670px;
    background-color: rgb(39 41 42 / 30%);
    position: relative;
    background-position: center;
    background-size: cover;
    bottom: 0;
    z-index: 2;

    @include respond-to('tablet') {
      width: calc(100%);
      height: 450px;
    }

    @include respond-to('phone') {
      width: calc(100% + 60px);
      margin-left: -30px;
      height: 397px;
      left: 0;
    }

    &__box {
      position: absolute;
      top: 420px;
      padding-bottom: 50px;

      @include respond-to('phone') {
        top: 75px;
        width: 100%;
      }

      @include respond-to('tablet') {
        top: 260px;
      }

      &__left {
        display: inline-block;
        vertical-align: top;
        margin-left: 100px;
        padding-top: 50px;

        @include respond-to('phone') {
          margin-left: 0;
          padding-left: 15px;
          width: 100%;
          display: none;
        }

        img {
        }
      }

      &__right {
        display: inline-block;
        vertical-align: top;
        margin-left: 30px;

        @include respond-to('phone') {
          margin-left: 0;
          padding-left: 30px;
          padding-right: 30px;
          display: block;
          vertical-align: top;
          width: 100%;
          margin-top: 100px;
        }

        &__category {
          font-size: 12px;
          font-weight: 600;
          line-height: 30px;
          text-transform: uppercase;
          color: #fff;
        }

        &__lead {
          font-size: 25px;
          font-weight: 600;
          letter-spacing: -0.59px;
          line-height: 35px;
          width: 440px;
          color: #fff;
          margin-top: 15px;

          @include respond-to('phone') {
            width: 100%;
          }
        }
      }
    }
  }
}

.career-article {
  width: 100%;
  display: grid;
  margin-top: 180px;
  position: relative;
  grid-template-columns: 3fr 1fr;

  @include respond-to('phone') {
    grid-template-columns: 1fr;
    margin-top: 140px;
    margin-bottom: 40px;
  }

  &__title {
    grid-row: 1;
    grid-column: 1 / 4;

    @include respond-to('phone') {
      margin-bottom: 40px;
    }

    &__h1 {
      font-family: 'Space Grotesk', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: -0.03em;
      color: #000;
    }
  }

  &__left {
    grid-column: 1 / 2;
    grid-row: 2;
    padding-right: 20%;

    @include respond-to('phone') {
      grid-row: 3;
      padding-right: 0;
    }

    &__lead {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: -0.01em;
      color: #000;
      margin-top: 24px;
    }

    &__text {
      margin-top: 60px;

      h2 {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: #000;
      }

      ul {
        padding-top: 30px;
        padding-bottom: 30px;

        li {
          font-size: 15px;
          font-weight: 500;
          line-height: 25px;
          background: url('img/tick.svg') no-repeat 2px 6px transparent;
          list-style-type: none;
          margin: 0;
          padding: 0 0 11px 42px;
          vertical-align: middle;

          &::before {
            padding-right: 8px;
          }

          b {
            font-weight: bold;
          }

          @include respond-to('fullhd') {
            font-size: 19px;
            line-height: 30px;
          }
        }
      }
    }
  }

  &__right {
    left: 0;
    top: 0;
    width: 320px;

    @include respond-to('phone') {
      width: 100%;
    }

    &__box {
      width: 320px;
      border: 1px solid #13101b;
      position: sticky;
      top: 0;
      margin-bottom: 30px;

      @include respond-to('phone') {
        width: 100%;
      }

      &__header {
        padding: 30px;
        background: rgb(239 239 239 / 50%);
        width: 100%;

        &__status {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          text-transform: uppercase;
          color: #13101b;
          margin-bottom: 20px;
        }

        &__price {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
          color: #13101b;

          div {
            font-family: 'Work Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -0.03em;
            color: #13101b;

            @include respond-to('phone') {
              font-size: 22px;
            }
          }
        }
      }

      &__body {
        padding: 30px;

        div {
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;

          /* identical to box height */

          text-transform: uppercase;
          color: #13101b;
          margin-bottom: 20px;
        }

        ul {
          list-style: none;
          padding-left: 15px;

          li {
            font-family: 'Work Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 19px;
            margin-bottom: 10px;

            //display: flex;
            align-items: center;
            letter-spacing: -0.01em;

            &::before {
              content: '\2022';
              color: #c4c4c4;
              font-weight: bold;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }
        }
      }

      &__button {
        background: #13101b;
        width: 100%;
        height: 80px;
        vertical-align: middle;
        text-align: center;
        color: #fff;
        display: grid;
        align-items: center;
      }
    }
  }
}

.career-box {
  .simple-text-boxes {
    margin: 50px 0;

    .simple-text-box {
      display: grid;
      border-top: 1px solid #13101b;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr;
      height: auto;
      place-content: center center;
      align-items: center;
      padding: 15px;
      color: #13101b;

      @include respond-to('phone') {
        grid-template-columns: 1fr;
      }

      &:hover {
        text-decoration: none;
      }

      .action-arrow {
        margin-top: 0;
      }

      &__button,
      &__price {
        width: 100%;
        display: grid;
        justify-items: center;
      }
    }
  }
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-top-0 {
  padding-top: 0;
}
