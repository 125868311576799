.services {
  width: 100%;
  position: relative;

  &__top {
    background-size: cover;
    width: 100%;
    height: 800px;
    position: relative;
    top: 0;
    left: 0;
    background-position: center center;

    @include respond-to('phone') {
      height: auto;
      padding-bottom: 80px;
    }

    .mask {
      background: #000;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 800px;
      opacity: 0.7;
      z-index: 2;

      @include respond-to('phone') {
        height: auto;
        padding-bottom: 80px;
        min-height: 395px;
      }
    }

    &__box {
      padding-top: 530px;
      position: relative;

      @include respond-to('phone') {
        padding-top: 150px;
      }

      z-index: 3;

      &__title {
        color: #e4a044;
        font-size: 45px;
        font-weight: 600;
        letter-spacing: -0.47px;
        line-height: 60px;
      }

      &__txt {
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        margin-top: 30px;
        color: #fff;
        width: 730px;

        @include respond-to('phone') {
          width: 100%;
        }
      }
    }
  }

  &__widget-category {
    width: 100%;
    height: 800px;
    position: relative;

    @include respond-to('phone') {
      height: auto;
    }

    &__box {
      width: 400px;
      padding-top: 200px;

      @include respond-to('phone') {
        width: 100%;
        padding-top: 50px;
      }

      &__topic {
        font-size: 25px;
        font-weight: 600;
        letter-spacing: -0.59px;
        line-height: 35px;
      }

      &__txt {
        color: #27292a;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        margin-top: 30px;
      }

      &__list {
        margin-top: 30px;

        @include respond-to('phone') {
          padding-bottom: 50px;
        }

        a {
          display: block;
          vertical-align: top;
        }
      }
    }
  }

  &__img {
    width: 50%;
    position: absolute;
    height: 500px;
    right: 0;
    top: 150px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;

    @include respond-to('phone') {
      width: 100%;
      position: relative;
      top: 0;
    }
  }
}
