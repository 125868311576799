.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}

.menu__link {
  padding-bottom: 0.8rem;
  position: relative;
  margin-top: 1rem;

}

//.is_open > .menu__link {
//  color: #fff;
//  text-decoration: underline;
//  text-decoration-color: $brand-yellow;
//  text-underline-offset: 5px;
//}

.menu__link-inner {
  display: block;
  position: relative;
  outline: 100px solid transparent;
}

.menu__link-deco {
  outline: 120px solid transparent;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.menu--line .menu__link-deco,
.menu--linethrough .menu__link-deco,
.menu--twolines .menu__link-deco,
.menu--diagonal .menu__link-deco {
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #efff04;
}

.menu--linethrough .menu__link-deco {
  bottom: 50%;
  height: 3px;
}

.menu--twolines .menu__link-deco {
  height: 100%;
  background: none;
  border: 1px solid currentcolor;
  border-right: 0;
  border-left: 0;
}

.menu--twolines .menu__link {
  padding: 0.95rem 0;
}

.menu--circle .menu__link-deco,
.menu--box .menu__link-deco {
  left: 50%;
  top: 50%;
  width: 5rem;
  height: 5rem;
  margin: -2.5rem 0 0 -2.5rem;
}

.menu--circle .menu__link-deco {
  border-radius: 50%;
  border: 1px solid black;
}

.menu--box .menu__link-deco {
  border: 1px solid black;
}

.menu--diagonal .menu__link-deco {
  bottom: 50%;
  width: 9rem;
  left: 50%;
  margin-left: -4.5rem;
  transform: rotate(-45deg);
}

.menu__link:hover .menu__link-deco {
  opacity: 1;
}
