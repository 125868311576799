#topvideo {
  display: block;
  vertical-align: top;
  height: 100vh;

  video {
    position: fixed;
    right: 0;
    z-index: 1;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

.langing-nav {
  position: relative;
  top: 51px;
  left: 0;
  z-index: 3;
}

.alpha {
  opacity: 0.7;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 2;
}

.join-us {
  position: relative;
  z-index: 5;
  margin-top: calc(-100vh + 170px);
  min-height: 100%;
  display: block;

  @include respond-to('phone') {
    margin-top: calc(-100vh + 100px);
  }

  &__topic {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    color: #fff;
    letter-spacing: -0.52px;

    @include respond-to('phone') {
      font-size: 36px;
    }

    span {
      color: #e4a044;
    }
  }

  &__box {
    margin-top: 45px;
    border: none;
    border-top: 1px solid rgb(255 255 255 / 20%);
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    padding-top: 33px;
    padding-bottom: 25px;
    display: inline-block;
    vertical-align: top;
    outline: none;
    height: 236px;
    width: calc(50% - 18px);

    @include respond-to('phone') {
      width: 100%;
      height: auto;
    }

    @include respond-to('tablet') {
      height: auto;
      min-height: 360px;
    }

    &:nth-of-type(2) {
      margin-right: 30px;

      @include respond-to('phone') {
        margin-right: 0;
      }
    }

    &--bold {
      color: #fff;
      font-size: 25px;
      font-weight: 600;
      line-height: 35px;
      letter-spacing: -0.59px;
      width: 80%;

      @include respond-to('phone') {
        width: 100%;
      }
    }

    &--light {
      font-size: 15px;
      font-weight: 400;
      line-height: 25px;
      color: rgb(255 255 255 / 70%);
      width: 90%;

      @include respond-to('phone') {
        width: 100%;
      }
    }
  }

  &__call {
    width: calc(50% - 18px);
    height: 71px;
    border: 1px solid #e4a044;
    margin-top: 50px;
    text-align: center;
    transition: all 0.6s;
    float: left;

    @include respond-to('phone') {
      width: 100%;
    }

    .action-arrow {
      padding-top: 22px;
    }
  }

  &__who {
    float: right;
    margin-top: 50px;

    @include respond-to('phone') {
      float: left;
    }

    img {
      float: left;
      margin-right: 20px;
    }

    &__name {
      color: #fff;
      font-size: 19px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.16px;
      float: left;

      span {
        width: 100%;
        display: block;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        color: rgb(255 255 255 / 50%);
      }
    }
  }

  &__contact {
    display: block;
    width: 100%;
    float: left;
  }

  &__offers {
    z-index: 6;
    display: block;
    height: 725px;
    width: 100%;

    &__offer {
      background: #fff;
      width: calc(50% - 18px);
      display: inline-block;
      vertical-align: top;
      padding: 35px;
      float: left;
      margin-top: 110px;
      height: 725px;
      position: relative;

      @include respond-to('phone') {
        width: 100%;
        height: auto;
        margin-top: 50px;
      }

      @include respond-to('tablet') {
        width: 100%;
        height: auto;
      }

      ul {
        @include respond-to('tablet') {
          margin-bottom: 90px;
        }
      }

      &:first-of-type {
        margin-right: 30px;

        @include respond-to('phone') {
          margin-right: 0;
        }

        @include respond-to('tablet') {
          margin-right: 0;
          height: 675px;
        }
      }

      &__join {
        color: #e4a044;
        font-size: 19px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.16px;
      }

      &__name {
        color: #000;
        font-size: 25px;
        font-weight: 600;
        line-height: 35px;
        text-transform: uppercase;
        letter-spacing: -0.59px;
      }

      &__if {
        color: #27292a;
        font-size: 19px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.16px;
      }

      &__list {
        margin-top: 30px;
        color: #27292a;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;

        ul {
          list-style: none;
          margin-left: 17px;

          li::before {
            content: '\2022';
            color: #e4a044;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }

          li {
            margin-top: 20px;
            width: 90%;

            @include respond-to('phone') {
              width: 100%;
            }
          }
        }
      }

      &__button {
        position: absolute;
        bottom: 30px;
        left: 30px;
        height: 71px;
        width: calc(100% - 60px);
        border: 1px solid #e4a044;
        text-align: center;

        .action-arrow {
          padding-top: 22px;
          color: #000;
        }

        .action-arrow__describe--cream {
          color: #000;
        }

        @include respond-to('phone') {
          position: relative;
          display: block;
          width: 100%;
          margin-top: 40px;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}

.box-salary {
  width: 1270px;
  left: calc(50% - (1270px / 2));
  border: 1px solid #e4a044;
  height: 400px;
  z-index: 4;
  position: relative;
  margin-top: 80px;
  top: 70px;
  margin-bottom: 150px;
  display: block;

  @include respond-to('phone') {
    width: 96%;
    margin-left: 2%;
    position: relative;
    left: 0;
    float: left;
    margin-top: 70px;
    height: auto;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }

  @include respond-to('tablet') {
    width: 96%;
    margin-left: 2%;
    position: relative;
    left: 0;
    float: left;
    margin-top: 70px;
    height: auto;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }

  &__info {
    color: #e4a044;
    font-size: 19px;
    font-weight: 500;
    position: relative;
    line-height: 26px;
    letter-spacing: -0.16px;
    margin-top: 10px;

    @include respond-to('phone') {
      margin-top: 30px;
    }

    @include respond-to('tablet') {
      margin-top: 60px;
    }
  }

  &__txt {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: -0.59px;
    margin-top: 10px;

    @include respond-to('phone') {
      font-size: 18px;
      line-height: normal;
    }

    span {
      color: #e4a044;
    }
  }
}
