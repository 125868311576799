.footer {
  display: flex;
  padding: 0 100px 104px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: $surface-primary;
  width: 100%;
  overflow: hidden;
  padding-bottom: 0;

  a {
    cursor: pointer;

    &:visited {
      color: inherit;
    }
  }

  @include max-desktop {
    padding: 0 16px;
  }

  &__text {
    color: $text-primary;
    font-variant-numeric: ordinal;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;

    &--mail {
      font-family: 'Space Grotesk', sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -1%;
      padding-bottom: 16px;

      @include max-desktop {
        text-align: center;
      }

      a {
        color: inherit;
      }
    }

    &--phone {
      color: $text-primary;
      font-family: 'Work Sans', serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 146.667% */
      padding-bottom: 4px;

      @include max-desktop {
        text-align: center;
      }

      a {
        color: inherit;
        transition: 0.1s;

        &:hover {
          transition: 0.1s;
          color: $brand-yellow;
        }
      }
    }
  }

  &__container {
    display: flex;
    padding: 104px 0;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    gap: 88px;
    align-self: stretch;

    @include max-desktop {
      padding: 46px 0;
      gap: 64px;
      padding-bottom: 30px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  &__partnersAndTerms {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    @include max-desktop {
      flex-direction: column;
      gap: 32px;
    }
  }

  &__partners {
    display: flex;
    align-items: center;
    gap: 56px;
    height: 35px;

    @include max-desktop {
      width: 100%;
      gap: 0;
      height: 24px;
    }
  }

  &__partnersList {
    display: flex;
    align-items: center;
    gap: 56px;

    li {
      height: 36px;
      display: flex;
      align-items: center;
    }

    @include max-desktop {
      gap: 0;
      justify-content: space-between;
      width: 100%;

      li {
        height: 24px;
      }
    }

    @media screen and (width <= 419px) {
      flex-wrap: wrap;

      li:first-of-type {
        width: 100%;
        text-align: center;
        margin-bottom: 16px;

        p {
          width: 100%;
        }
      }
    }
  }

  &__terms {
    display: flex;
    gap: 56px;

    @include max-desktop {
      gap: 0;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.footerSocials {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
  padding-bottom: 55px;
  border-top: 1px solid $grey-dark;
  border-bottom: 1px solid $grey-dark;

  @include max-desktop {
    padding: 46px 0;
    flex-direction: column;
    align-items: center;
    gap: 46px;
  }

  @include max-desktop {
    padding: 46px 0;
    flex-direction: column;
    align-items: center;
    gap: 46px;
  }

  &__list {
    display: flex;
    gap: 32px;

    li {
      height: 24px;
    }

    @media screen and (width <= 499px) {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.footerContactAndOpinions {
  padding-top: 55px;
  padding-bottom: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-full-hd {
    flex-direction: column;
    padding-top: 46px;
    padding-bottom: 46px;
    gap: 46px;
  }

  &__list {
    height: 80px;
    display: flex;
    align-items: center;
    gap: 74px;

    @media screen and (width <= 1599px) {
      gap: 40px;
    }

    @include max-full-hd {
      width: 100%;
      justify-content: space-between;
    }

    @include max-desktop {
      width: 100%;
      gap: 0;
    }

    @media screen and (width <= 768px) {
      height: 50px;
    }
  }

  &__listMobile {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include max-desktop {
      display: flex;
    }
  }

  &__listItem {
    height: 100%;

    &--clutchReview {
      height: 34px;

      @include max-desktop {
        display: none;
      }
    }

    &--nps {
      height: 60px;

      @include max-desktop {
        display: none;
      }
    }
  }
}

.footerImg {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.footerLocations {
  display: flex;
  width: 100%;

  @include max-desktop {
    flex-direction: column;
  }

  &__box {
    flex-grow: 1;
    border-top: 1px solid $grey-dark;
    border-left: 1px solid $grey-dark;
    border-bottom: 1px solid $grey-dark;
    padding: 30px;
    max-width: 25%;

    @include max-desktop {
      width: 100%;
      max-width: none;
      border: none;
      border-top: 1px solid $grey-dark;
      border-left: 1px solid $grey-dark;
      border-right: 1px solid $grey-dark;
    }

    &--black {
      background: $surface-primary;
    }

    &--yellow {
      background: $brand-yellow;
    }
  }

  &__title {
    font-family: 'Space Grotesk', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 8px;

    &--white {
      color: $text-primary;
    }

    &--black {
      color: $surface-primary;
    }
  }

  &__text {
    font-family: "Work Sans", serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;

    &--white {
      color: $text-primary;
    }

    &--black {
      color: $surface-primary;
    }
  }

  &__scheduleMeeting {
    color: inherit;
    font-family: "Space Grotesk", serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: left;
    padding: 6.5px 0;
    margin-top: 14px;
    display: flex;
    justify-content: space-between;

    &:hover {
      img {
        transition: 0.2s;
        transform: rotate(0deg);
      }
    }
  }

  &__arrow {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $surface-primary;

    img {
      height: 8px;
      width: auto;
      object-fit: cover;
      transition: 0.2s;
      transform: rotate(45deg);
    }
  }
}
