.grid-column-two {
  grid-template-columns: 5fr 2fr;
  display: grid;

  @include respond-to('phone') {
    grid-template-columns: 1fr;
  }
}

.section-container {
  padding-left: 104px;
  padding-right: 104px;
  width: 100%;
  margin: auto;

  @include respond-to('fullhd') {
    padding: 0 104px;
  }

  @include respond-to('tablet') {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  @include respond-to('phone') {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  &--padding-vertical {
    padding-top: 60px;
    padding-bottom: 60px;

    &--widget {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @include respond-to('fullhd') {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include respond-to('tablet') {
      width: 100%;
      padding-top: 80px;
      padding-bottom: 80px;
    }

    @include respond-to('phone') {
      width: 100%;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &--black {
    background: $surface-primary;
    color: #f5f6f7;
  }

  &--white {
    color: #0b0a0e;
    background: white;

    .typography--lightGrey {
      color: #0b0a0e !important;
    }
  }

  &--big-text {
    display: flex;
    justify-content: flex-end;
  }

  &--h2-small {
  }

  &--one-row {
    width: calc(100% / 3 * 2);
  }

  &--two-columns {
    align-items: center;
    display: grid;

    @include respond-to('phone') {
      width: 100%;
      margin-top: 40px;
    }
  }
}
