.green-tech-top-minimal {
  display: flex;
  gap: 32px;
  padding-bottom: 104px;
  padding-top: 104px;

  @include respond-to('phone') {
    padding-bottom: 15px;
  }

  &--reversed {
    flex-direction: row-reverse;
  }

  @include respond-to('phone_tablet') {
    flex-flow: column-reverse wrap;
  }

  &__column {
    width: 50%;

    @include respond-to('phone_tablet') {
      width: 100%;
    }

    &--content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > div {
        margin: 0 auto;
        width: 70%;

        @include respond-to('phone_tablet') {
          width: 100%;
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 5 / 3;


  }

  &__title {
    margin: 16px 0;
  }

  &__description {
    margin-bottom: 48px;
  }

  &__button-wrapper {
    min-width: 70%;

    @include respond-to('phone_tablet') {
      width: 100%;
    }
  }
}
