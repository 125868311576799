.minimal-top {
  width: 100%;
  position: relative;
  padding-bottom: 100px;
  padding-top: 170px;
  color: #21211f;

  @include respond-to('fullhd') {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  @include respond-to('phone') {
    padding-top: 140px;
    padding-bottom: 80px;
  }

  &--h2 {
    padding-top: 80px;
    padding-bottom: 80px;

    @include respond-to('phone_tablet') {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include respond-to('fullhd') {
      padding-top: 104px;
      padding-bottom: 104px;
    }
  }

  video {
    @include respond-to('phone') {
      display: none;
    }
  }

  &.padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  .container {
    padding: 0 150px !important;
    margin: auto !important;
    width: auto !important;

    @include respond-to('fullhd') {
      padding: 0 104px !important;
    }

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }

  .container-main {
    @include respond-to('fullhd') {
      padding: 0 104px !important;
      width: auto !important;
    }
  }

  &--black {
    background: #0b0a0e;

    h1,
    p,
    h2 {
      color: #fff !important;
    }
  }

  h1,
  h2 {
    @extend .typography--h2;

    padding-bottom: 30px;
    max-width: 540px;
  }

  &--h2 {
    h1,
    h2 {
      max-width: 600px;

      @include respond-to('fullhd') {
        max-width: 1000px;
      }

      @include respond-to('phone_tablet') {
        max-width: 600px;
      }
    }
  }

  p {
    @extend  .typography--bodyLargeRegular
  }

  &--h2 {
    p {
      max-width: 600px;

      @include respond-to('fullhd') {
        max-width: 1000px;
      }

      @include respond-to('phone_tablet') {
        max-width: 600px;
      }
    }
  }
}
