.navbar {
  display: block;
  vertical-align: top;
  color: #fff;
  padding: 35px;
  padding-top: 30px;
  padding-bottom: 30px;
  position: fixed;
  z-index: 1040;
  width: 100%;
  top: 0;
  background: #0b0a0e;
  transition: top 0.3s;

  @include respond-to('phone_tablet') {
    height: auto;
    min-height: 80px;
    overflow: hidden;
    padding: 30px 16px;
  }

  @include respond-to('phone') {
    display: grid;
  }

  &.black {
    //position: relative;
  }

  @include respond-to('phone_tablet') {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__href {
    display: inline-grid;
    vertical-align: middle;
    align-content: center;
    height: 45px;
    width: 180px;
    z-index: 9999;

    &__logo {
      background: url('img/ordergroup-logo.svg') no-repeat;
      width: 100%;
      z-index: 9999;
      height: 25px;
      background-size: contain;

      @include respond-to('phone_tablet') {
        width: 180px;
        align-content: center;
      }

      &--black {
        background-image: url('img/logo-og-black.svg');
      }
    }
  }

  &__eu {
    display: inline-block;
    vertical-align: top;
    margin-left: 69px;
    margin-top: 7px;
    width: 234px;

    @include respond-to('phone_tablet') {
      display: none;
    }
  }

  &__list {
    display: inline-flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
    height: 47px;
    text-align: right;
    float: right;

    @include respond-to('phone_tablet') {
      height: auto;
      position: absolute;
      bottom: 0;
    }

    & > li {
      margin: 0 1rem;
    }

    &__element {
      display: inline-block;
      vertical-align: top;
      margin-right: 50px;

      &:hover,
      &:focus {
        .nav-submenu {
          @include respond-to('phone') {
            display: none;
          }
        }
      }

      &:nth-last-child {
        margin-right: 0;
      }

      @include respond-to('phone') {
        display: none;
      }

      .social {
        display: none;

        @include respond-to('phone_tablet') {
          display: block;
          margin-top: 50px;

          a {
            margin-right: 15px;
          }
        }
      }

      .number-of-jobs {
        display: flex;
        align-items: flex-start;
        padding-left: 5px;
        color: $brand-yellow;
      }

      &__href {
        color: $text-primary;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        transition: 0.8s all;
        text-align: left;
        font-family: 'Space Grotesk', sans-serif;
        line-height: 120%;
        position: relative;

        @include respond-to('phone_tablet') {
          font-size: 36px;
          font-weight: 300;
          color: #302f2c;
          letter-spacing: -1px;
          line-height: 44px;
          text-transform: none;

          //text-decoration-color: $brand-yellow;
        }

        @include respond-to('fullhd') {
          font-size: 16px;
        }

        @include respond-to('tablet') {
          font-size: 40px;
        }

        //&:hover {
        //  color: $brand-yellow;
        //}

        .underline {
          position: absolute;
          bottom: 5px;
          left: 0;
          height: 2px; /* grubość „podkreślenia” */
          width: 100%; /* będzie się skalować, ale warto ustawić domyślną szerokość */
          background-color: #efff04;

          /* startowe zwinięcie kreski */
          transform: scaleX(0);
          transform-origin: left center;
        }

        //&.active {
        //
        //  text-decoration: underline;
        //  text-decoration-color: $brand-yellow;
        //  text-underline-offset: 5px;
        //
        //  @include respond-to('phone_tablet') {
        //    color: #302f2c;
        //    font-weight: 600;
        //  }
        //}
      }
    }
  }
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  z-index: 9999;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #191a19;
  padding: 20px;
  border-radius: 50%;
  position: fixed;
  top: 30px;
  right: 15px;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #13101b;
  position: fixed;
  height: 2px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button.black,
.menu-button.black::before,
.menu-button.black::after {
  background-color: #000;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

.navbar__list > li {
  display: flex;
  justify-content: left;

  &:last-child {
    a {
      @include respond-to('phone_tablet') {
        padding: 0;
        font-size: 36px !important;
        display: flex;
        height: 50px !important;
      }
    }
  }

  @include respond-to('phone_tablet') {
    display: none;
    width: 100%;
  }
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgb(255 255 255 / 0%);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0;
  transform: rotate(-405deg);
}

.inner-container {
  float: right;

  @include respond-to('phone_tablet') {
    visibility: hidden;
    overflow: hidden;
  }

  @include respond-to('tablet') {
    background: #13101b;
  }
}

@include respond-to('phone_tablet') {
  .menu-button-container {
    display: flex;
    height: 40px;
    padding: 30px;
    margin-top: -7px;
  }

  .navbar__list {
    padding: 0 16px;
    flex-direction: column;
  }

  .overflow-hidden .navbar__href__logo {
    background: url('img/ordergroup-logo.svg') no-repeat;
    background-size: contain;
  }

  .overflow-hidden .navbar__list {
    z-index: 9998;
    padding-bottom: 150px;
  }

  .overflow-hidden .navbar__list li {
    height: 80px;
    z-index: 800;

    @include respond-to('phone_tablet') {
      height: auto;
    }

    @include respond-to('phone_tablet') {
      display: flex;
    }
  }

  // New menu animation
  .menu-trigger,
  .close-trigger {
    position: absolute;
    top: 16px;
    right: 20px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 999999;
  }

  .menu-trigger {
    grid-template-columns: 1fr 1fr;
    column-gap: 2px;
    display: grid;
    top: 34px;
  }

  .menu-trigger:hover .menu-trigger-bar::before,
  .close-trigger:hover .menu-trigger-bar::before {
    width: 100%;
  }

  .menu-trigger:hover .close-trigger-bar::before,
  .close-trigger:hover .close-trigger-bar::before {
    width: 100%;
  }

  .close-trigger {
    z-index: 99999;
    top: 50px;
  }

  .menu-trigger-bar {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #f0edeb;
    position: relative;
  }

  .menu-trigger-bar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0%;
    height: 100%;
    background-color: rgb(0 0 0 / 20%);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .close-trigger-bar {
    display: block;
    width: 58%;
    height: 4px;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
  }

  .close-trigger-bar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0%;
    height: 100%;
    background-color: rgb(255 255 255 / 20%);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .close-trigger-bar.left {
    transform: translateX(100px) translateY(-100px) rotate(-45deg);
  }

  .close-trigger-bar.right {
    transform: translateX(-100px) translateY(-100px) rotate(45deg);
    top: -4px;
  }

  .inner-container,
  .menu-container {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    align-items: flex-end;
  }

  .inner-container {
    z-index: 20;
  }

  .menu-container {
    display: flex;
    align-items: center;

    .navbar__list__element__href {
      color: #fff;
    }
  }

  .menu {
    opacity: 0;
  }

  .menu a {
    padding: 0;
  }

  .menu a:hover::before {
    opacity: 1;
    transform: translateX(0);
  }

  .menu a::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 18px;
    height: 4px;
    opacity: 0;
    background-color: #efff04;
    transform: translateX(100px);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .menu-bg {
    position: absolute;
    display: block;
    width: 245%;
    left: -53%;
    top: -25%;
    height: 51%;
    background-color: #13101b;

    @include respond-to('tablet') {
      height: 56%;
    }
  }

  .menu-bg.middle {
    top: 26%;
    left: -60%;
    transform: rotate(-45deg) scaleY(0);
  }

  .menu-bg.top {
    left: -57%;
    top: -15%;
    transform: rotate(-45deg) translateY(-152%);
  }

  .menu-bg.bottom {
    top: 105%;
    transform: rotate(-45deg) translateY(25%);
    left: -20%;
  }
}

.border-menu-trigger {
  @include respond-to('phone_tablet') {
    position: fixed;
    top: 23px;
    right: 15px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

.navbar__list__element.menu--line.is_open {
  .nav-submenu {
    opacity: 1;
    transition: opacity 1.5s;
    visibility: visible;

  }
}

.submenu-overlay {
  position: absolute;
  background: #0b0a0e;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s;
  transform: translateY(107px);
  will-change: transform;
  z-index: 800;

  @include respond-to('phone') {
    display: none;
  }
}


.nav-submenu {
  position: absolute;
  top: 107px;
  left: 0;
  width: 100%;
  transform: translateY(0);
  will-change: transform;
  padding: 40px;
  padding-bottom: 60px;
  opacity: 0;
  visibility: hidden;
  z-index: 900;


  @include respond-to('phone') {
    display: none;
  }

  .container {
    width: 100%;
  }

  li {
    a {
      &:hover {
        color: #aeb4bb;
      }
    }
  }

  &__submenu {
    color: $text-primary;
    margin-bottom: 20px;
  }
}

.submenu {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  grid-gap: 32px;
  place-content: normal normal;
  position: relative;
  z-index: 9999;

  &--promo {
    grid-template-columns: 1fr 3fr;
  }

  &__describe {
    border-right: 1px solid $grey-dark;
    padding-right: 24px;

    p {
      margin-top: 16px;
      width: 70%;
    }

    a {
      margin-top: 16px;
      display: block;
    }
  }

  &__content {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
    display: grid;

    &--two-column {
      grid-template-columns: 1fr 1fr;
    }

    li {
      margin-bottom: 24px;
    }
  }

  &__promo {
    display: grid;
  }
}

.show-menu {
  visibility: visible;
  opacity: 1;
  background: $surface-primary;
  transition: all 0.4s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.top-null {
  top: 0 !important;
}

.show-rotate-left {
  transform: rotate(45deg) !important;
  transition: all 0.4s;
}

.show-rotate-right {
  transform: rotate(-45deg) !important;
  transition: all 0.4s;
}

.menu {
  &.show {
    opacity: 1;
    transition: all 0.4s;
  }
}

.inner-container {
  transition: all 0.4s;
}

.main-menu-wrapper {
  @include respond-to('phone_tablet') {
    ul {
      height: calc(100vh - 105px) !important;
      width: 100%;
      overflow: hidden auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  //@media screen and (max-height: 805px) {
  //  ul {
  //    display: block;
  //  }
  //}
}


.menu-final {
  display: grid;
  grid-template-columns: 1fr;


  span {
    margin-top: 4px;
    width: 70%;
    display: block;
  }

  &--two {
    grid-template-columns: 24px 1fr;
    grid-gap: 12px;
  }

  &__link {
    a {
      &:hover {
        color: #efff04 !important;
      }
    }
  }
}
