.filter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 40px;
  column-gap: 50px;
  padding-bottom: 40px;

  @include respond-to('phone') {
    grid-template-columns: repeat(1, 1fr);
  }

  &__box {
    padding-bottom: 40px;
    border-right: 1px solid #2e2e31;

    @include respond-to('phone') {
      border: none;
      height: auto;
      padding-bottom: 40px;
    }

    &:nth-child(3) {
      border-right: none;
    }

    h3 {
      font-family: 'Space Grotesk', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 29px;
      letter-spacing: -0.01em;
      color: #fff;
    }

    ul {
      margin-top: 22px;
      list-style: none;

      li {
        display: block;
        width: 100%;

        a {
          font-family: 'Work Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          color: #c6c1ba;

          &:hover {
            color: #efff04 !important;
          }
        }
      }
    }
  }
}
