.section-filter {
  padding-top: 104px;
  padding-bottom: 70px;

  &__title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 72px;

    h2 {
      margin-top: 8px;
    }

    a {
      width: 360px;
    }

    @media screen and (width <= 1023px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;

      & > div {
        margin-bottom: 12px;
      }

      a {
        width: 100%;
      }
    }
  }

  &__list-wrapper {
    p {
      margin-bottom: 24px;
    }
  }
}

.section-filter-list {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $grey-dark;
  border-left: 1px solid $grey-dark;

  &__item {
    width: 25%;
    border-right: 1px solid $grey-dark;
    border-bottom: 1px solid $grey-dark;

    @media screen and (width <= 1023px) {
      width: 33.33%;
    }

    @media screen and (width <= 767px) {
      width: 50%;
    }

    @media screen and (width <= 500px) {
      width: 100%;
    }
  }
}
