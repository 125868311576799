.blog-articles-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @include respond-to(wxga) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond-to(phone) {
    grid-template-columns: 1fr;
  }

  &--gap {
    gap: 32px;

    @include respond-to(hd_plus) {
      gap: 20px;
    }
  }

  li {
    position: relative;
  }

  &__item {
    padding-bottom: 38px;
  }

  &__content {
    padding-right: 24px;
  }

  &__tag-list {
    display: flex;
    gap: 24px;

    &--mb {
      margin-top: 32px;

    }
  }

  &__tag {
    background: white;
    padding: 4px 8px;
    color: $surface-primary !important;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;

    &--yellow {
      background: #efff04;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    &--promoted {
      margin: 0;
      width: 60%;

      @include respond-to(phone_tablet) {
        width: 50%;
      }

      @include respond-to(phone) {
        width: 100%;
      }

      @include respond-to(phone) {
        margin-bottom: 28px;
      }
    }
  }

  &__title {
    padding: 10px 0;
    margin-top: 40px;

    @include respond-to(hd_plus) {
      margin-top: 28px;
      font-size: 20px;
    }
  }

  &__read-more-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 32px;
    height: 40px;

    @include respond-to(hd_plus) {
      height: 32px;
    }
  }

  &__read-more-link {
    display: flex;
    align-items: center;
    font-size: 16px;
    position: absolute;
    bottom: 0;
    right: 0;

    &:hover {
      cursor: pointer;

      img {
        transition: 0.2s;
        transform: rotate(-45deg);
      }
    }
  }

  &__read-more-arrow {
    width: 24px;
    height: 24px;
    background: $brand-yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;

    img {
      transition: 0.2s;
    }
  }
}

.promoted-articles-list {
  display: grid;
  margin-bottom: 104px;
  margin-top: 104px;
  grid-template-areas:
    'a b'
    'a c';

  &--widget {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @include respond-to(phone_tablet) {
    display: flex;
    flex-direction: column;
  }

  li:nth-of-type(1) {
    grid-area: a;

    a {
      flex-direction: column;

      @include respond-to(phone_tablet) {
        display: flex;
        flex-direction: row;
      }
    }

    .blog-articles-list__image--promoted {
      width: 100%;
      margin-bottom: 40px;

      @include respond-to(hd_plus) {
        margin-bottom: 28px;
      }
    }

    .promoted-articles-list__content {
      width: 100%;
      padding-left: 0;

      @include respond-to(phone_tablet) {
        padding-left: 28px;
      }
    }
  }

  li:nth-child(2) {
    grid-area: b;
  }

  li:last-of-type {
    grid-area: c;
  }

  &__item {
    display: flex;
    text-decoration: none !important;

    @include respond-to(phone) {
      flex-direction: column !important;
    }
  }

  &__content {
    width: 40%;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-to(hd_plus) {
      padding-left: 28px;
    }

    @include respond-to(phone_tablet) {
      width: 50%;
    }

    @include respond-to(phone) {
      width: 100%;
      padding-left: 0 !important;
    }
  }
}

.section-filter-loader-wrapper {
  height: 300px;
}
