.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: $og-dark-gray;
  border-radius: 24px;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
}

input {
  &:checked + .slider {
    background-color: $og-green;
  }

  &:focus + .slider {
    box-shadow: 0 0 1px $og-green;
  }

  &:checked + .slider::before {
    transform: translateX(16px);
  }
}
