.details-home {
  height: 80vh;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  display: flex;
  min-height: 600px;

  @include respond-to('phone') {
    min-height: 100%;
    padding-top: 150px;
    padding-bottom: 80px;
    height: 90vh;
  }

  .container {
    padding: 0 150px !important;
    width: auto !important;
    margin: auto !important;

    @include respond-to('fullhd') {
      padding: 0 104px !important;
    }

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }

  &__video {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 73%;
    height: calc(100% - 100px);
    z-index: 999;

    @include respond-to('fullhd') {
      width: 75%;
    }

    @include respond-to('phone') {
      top: 100px;
      right: 0;
      width: 82%;
      height: 43vh;
    }
  }

  &__body {
    z-index: 9999;
    position: relative;
    display: grid;

    h1 {
      font-family: 'Space Grotesk', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      line-height: 52px;
      letter-spacing: -0.04em;
      font-feature-settings: 'dnom' on, 'numr' on, 'sinf' on, 'case' on, 'zero' on, 'ss04' on, 'ss05' on, 'ss03' on,
      'ss01' on, 'salt' on, 'ordn' on;
      color: #efff04;
      width: 400px;

      @include respond-to('phone') {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 40px;
      }
    }

    p {
      margin-top: 40px;
      width: 360px;
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.05em;
      color: #fff;

      @include respond-to('phone') {
        width: 100%;
        font-weight: 500;
      }
    }

    &__button {
      position: absolute;
      width: fit-content;
      height: 80px;
      bottom: 1px;
      display: grid;
      margin-bottom: 3px;
      grid-template-columns: 1fr 80px;
      transition: all 0.3s;

      &.no-absolute {
        position: relative;
        margin-top: 40px;
      }

      @include respond-to('phone') {
        position: relative;
        margin-top: 40px;
        width: 100%;
        height: 83px;
      }

      &__txt {
        border: 2px solid #efff04;
        padding: 24px;
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        color: #efff04;
        align-content: center;

        @include respond-to('phone') {
          display: grid;
          align-content: center;
          height: 84px;
          line-height: 23px;
        }
      }

      &__sign {
        background: #efff04;
        width: 80px;
        height: 84px;
        place-content: center center;
        display: grid;

        &__icon {
          background-image: url('img/sign.svg');
          text-align: center;
          background-size: auto;
          background-repeat: no-repeat;
          background-position: center;
          transform: rotate(0deg);
          transition: all 0.6s;
          width: 34px;
          height: 34px;
        }
      }

      &:hover {
        .details-home__body__button__sign__icon {
          transform: rotate(45deg);
          transition: all 0.3s;
        }
      }
    }
  }
}

.details {
  padding-bottom: 80px;

  &__top {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    background-size: cover;
    background-position: center center;
    position: relative;

    @include respond-to('phone') {
      min-height: 100%;
      padding-top: 120px;
      padding-bottom: 80px;
    }

    .case-number {
      position: absolute !important;
      bottom: 30px;
    }

    @include respond-to('tablet') {
      height: 100vh;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include respond-to('phone') {
        display: none;
      }
    }

    .container {
      padding: 0 150px !important;
      width: auto !important;
      margin: auto !important;

      @include respond-to('fullhd') {
        padding: 0 104px !important;
      }

      @include respond-to('tablet') {
        width: 100% !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      @include respond-to('phone') {
        width: 100% !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
      }
    }

    .details__body {
      color: #fff;
      position: absolute;
      bottom: 80px;
      width: 100%;

      h1 {
        color: #fff;
      }

      @include respond-to('phone') {
        height: 70vh;
      }

      &__title {
        @include respond-to('phone') {
          color: #fff;
        }
      }

      &__subtitle {
        @include respond-to('phone') {
          color: #fff;
          font-size: 25px;
        }
      }
    }

    &__case {
      position: relative;
      width: 100%;
      margin: auto;
      z-index: 9989;
      display: inline-flex;
      height: 100vh;
      align-content: center;

      @include respond-to('phone') {
        padding-bottom: 40px;
      }

      .container {
        width: 820px !important;
        padding: 0 !important;
        padding-top: 30px;

        @include respond-to('tablet') {
          width: calc(100%) !important;
          padding-left: 100px !important;
          padding-right: 100px !important;
        }

        @include respond-to('phone') {
          width: 100% !important;
          padding-left: 50px !important;
          padding-right: 50px !important;
        }

        @include respond-to('fullhd') {
          width: 1000px !important;
        }
      }

      @include respond-to('phone_tablet') {
        width: 100%;
      }

      &__category {
        color: #e4a044;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        margin-top: 0;
        width: 100%;
        padding-top: 0;
        padding-bottom: 20px;
        text-align: center;

        @include respond-to('fullhd') {
          font-size: 16px;
          padding-bottom: 30px;
        }

        @include respond-to('phone') {
          padding-bottom: 15px;
        }
      }

      &__lead {
        font-size: 36px;
        font-weight: 300;
        letter-spacing: -1px;
        line-height: 44px;
        color: #fff;
        text-align: center;

        @include respond-to('fullhd') {
          font-size: 48px;
          line-height: 60px;
          letter-spacing: -1px;
          font-weight: 300;
        }
      }

      &__name {
        font-size: 45px;
        font-weight: 600;
        letter-spacing: -1.5px;
        line-height: 60px;
        color: #fff;
        text-align: center;
        word-break: break-word;
        padding-bottom: 20px;

        @include respond-to('phone') {
          line-height: 55px;
        }

        @include respond-to('tablet') {
          font-size: 45px;
          line-height: normal;
        }

        @include respond-to('fullhd') {
          font-size: 64px !important;
          line-height: 80px !important;
          padding-bottom: 40px;
        }
      }
    }
  }

  &__body {
    padding-top: 0;
    color: #1a1b1c;
    font-size: 19px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.3px;
    max-width: 100%;

    //display: grid;
    //overflow: hidden;

    @include respond-to('phone') {
      padding-top: 0;
    }

    dl {
      width: 890px;
      margin: auto;
      padding-top: 30px;
      padding-bottom: 30px;

      @include respond-to('phone') {
        width: 100%;
        padding: 30px;
      }
    }

    .container {
      width: auto;

      @include respond-to('phone') {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
      }

      @include respond-to('tablet') {
        padding-left: 30px;
        padding-right: 30px;
      }

      &.fit-container {
        @include respond-to('phone') {
          padding: 0 !important;
        }
      }
    }

    &__author {
      width: 100%;
      display: block;
      vertical-align: top;
      margin-bottom: 30px;
      margin-top: 30px;

      @include respond-to('phone') {
        margin-top: 0;
      }

      &__img {
        background-size: cover;
        height: 50px;
        width: 50px;
        border-radius: 25px;
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
        border: 1px solid #979797;
      }

      &__name {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        padding-top: 10px;
        display: inline-block;
        vertical-align: top;

        @include respond-to('phone') {
          width: calc(100% - 76px);
          padding-top: 0;
        }

        & > div {
          display: block;
          vertical-align: top;
          line-height: 17px;

          &:last-child {
            font-weight: 300;
          }
        }
      }
    }

    &__title {
      color: #000;
      font-size: 45px;
      font-weight: 600;
      letter-spacing: -0.47px;
      line-height: 60px;

      @include respond-to('phone') {
        font-size: 35px;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: -0.36px;
      }
    }

    &__subtitle {
      font-size: 36px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 50px;
      width: 890px;
      margin-top: 20px;

      @include respond-to('phone') {
        width: 100%;
        text-align: left;
        color: #000;
      }
    }

    &__case {
      padding-bottom: 40px;
      padding-top: 40px;

      @include respond-to('phone') {
        padding-bottom: 65px;
      }

      .container {
        padding: 0 50px !important;
        margin: auto !important;
        width: auto !important;

        @include respond-to('hd') {
          padding: 0 150px !important;
          width: auto !important;
        }

        @include respond-to('fullhd') {
          padding: 0 104px !important;
          width: auto !important;
        }

        @include respond-to('tablet') {
          width: 100% !important;
          padding-left: 30px !important;
          padding-right: 30px !important;
        }

        @include respond-to('phone') {
          width: 100% !important;
          padding-left: 30px !important;
          padding-right: 30px !important;
        }
      }

      &__left {
        display: grid;
        vertical-align: top;
        width: 33%;
        height: 180px;
        float: left;
        border: 1px solid #e2e2e2;
        border-right: none;

        @include respond-to('phone') {
          width: 100%;
          height: auto;
          border: 1px solid #e2e2e2;
        }

        &__logo {
          height: 178px;
          justify-self: center;
        }
      }

      &__right {
        width: 66.66%;
        box-sizing: border-box;
        height: 180px;
        display: inline-block;
        vertical-align: top;
        float: left;
        text-align: left;
        padding: 60px;
        padding-top: 40px;
        border: 1px solid #e2e2e2;

        @include respond-to('phone') {
          width: 100%;
          float: left;
          text-align: left;
          padding: 40px;
          height: auto;
          border-top: none;
        }

        &__describe {
          color: #979797;
          font-size: 15px;
          line-height: 25px;
          padding-bottom: 20px;
        }
      }
    }

    &__testimonial {
      width: 970px;
      margin: auto;
      padding-top: 80px;
      padding-bottom: 80px;

      @include respond-to('phone_tablet') {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }

      &__info {
        display: block;
        vertical-align: top;
        margin-bottom: 25px;

        &__img {
          border-radius: 3px;
          height: 60px;
          width: 60px;
          margin-right: 20px;
          position: relative;
          display: inline-block;
          vertical-align: top;
          background-size: cover;
          background-position: center center;
        }

        &__icon {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 20px;
          height: 20px;
          background-color: #efff04;
          display: grid;
          place-items: center center;

          &.case-studies {
            img {
              width: 65%;
            }
          }
        }

        &__name {
          display: inline-block;
          vertical-align: top;
          font-size: 12px;
          font-weight: 500;
          line-height: 52px;
          text-transform: uppercase;
          margin-left: 20px;

          @include respond-to('phone') {
            margin-left: 10px;
          }

          &--position {
            color: #e4a044;
          }
        }
      }

      &__txt {
        font-size: 36px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 45px;

        @include respond-to('phone') {
          font-size: 28px;
          font-weight: 300;
          letter-spacing: -0.32px;
          line-height: 40px;
        }
      }
    }

    .details-new {
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;

      @include respond-to('phone') {
        background-position: center;
      }

      &__href {
        &__button {
          width: calc(100% + 1px);
          margin-bottom: -1px;
          background-color: #efff04;
          padding: 24px;
          color: #0b0a0e;
          padding-left: 53px;
          margin-top: 2px;
          font-family: 'Space Grotesk', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 32px;
          letter-spacing: -0.04em;
          position: relative;
          margin-left: -1px;

          &__arrow {
            display: grid;
            background-size: cover;
            position: absolute;
            right: 26px;
            bottom: 29px;
            width: 20px;
            height: 20px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }

        &:hover {
          .details-new__href__button__arrow img {
            transform: rotate(-45deg);
            transition: all 0.3s;
          }
        }
      }
    }

    &__box-action {
      width: 40%;
      border: 3px solid #efff04;
      height: auto;

      @include respond-to('phone_tablet') {
        width: 100%;
      }

      &__txt {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.04em;
        color: #fff;
        text-align: left;
        padding: 40px;
        padding-left: 53px;

        @include respond-to('fullhd') {
          font-size: 50px;
          line-height: 60px;
        }

        @include respond-to('phone') {
        }
      }
    }

    &__full-box {
      width: 100%;
      padding-bottom: 0;
      padding-top: 40px;

      &__wrapper {
        width: 100%;

        img {
          height: auto;
          width: 80%;
          margin-left: 10%;
          display: grid;

          @include respond-to('phone') {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      &__topic {
        font-size: 36px;
        font-weight: 400;
        letter-spacing: -1px;
        line-height: 45px;
        text-align: center;
        width: 100%;
        color: #000;
        padding-top: 60px;
        font-family: 'Space Grotesk', sans-serif;

        @include respond-to('phone') {
          font-size: 32px;
          font-weight: 300;
          letter-spacing: -1px;
          line-height: 40px;
          padding-top: 40px;
        }

        @include respond-to('fullhd') {
          font-size: 50px;
          line-height: 60px;
        }
      }

      &__lead {
        font-size: 19px;
        font-weight: 300;
        letter-spacing: -0.16px;
        line-height: 26px;
        padding-top: 25px;
        padding-bottom: 75px;
        text-align: center;
        margin: auto;
        width: 770px;

        @include respond-to('phone') {
          width: 100%;
          font-size: 21px;
          line-height: 30px;
        }

        @include respond-to('fullhd') {
          width: 1000px;
          font-size: 28px;
          line-height: 44px;
          letter-spacing: -1px;
        }

        @include respond-to('tablet') {
          width: 100%;
        }
      }

      img {
        margin-bottom: 0 !important;
      }
    }

    &__order {
      margin: auto;
      border-radius: 3px;
      width: 970px;
      border: 1px solid #e2e2e2;
      padding: 70px 50px;

      @include respond-to('phone_tablet') {
        width: 100%;
        padding: 30px 20px 20px;
      }

      &__icon {
        display: inline-block;
        vertical-align: top;

        @include respond-to('phone') {
          display: block;
          margin-bottom: 20px;
        }
      }

      &__txt {
        display: inline-block;
        vertical-align: top;
        font-size: 45px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 50px;
        color: #000;
        margin-left: 23px;
        width: calc(100% - 50px);

        @include respond-to('phone') {
          margin-left: 0;
          width: 100%;
          font-size: 32px;
          font-weight: 700;
          letter-spacing: -0.71px;
          line-height: 40px;
        }

        span {
          color: #e4a044;
        }
      }
    }

    &__content {
      p {
        color: #1a1b1c;
        font-size: 19px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.3px;

        @include respond-to('fullhd') {
          font-size: 26px;
          line-height: 44px;
          letter-spacing: -1px;
        }

        @include respond-to('phone') {
          font-size: 20px;
        }
      }

      & > div {
        padding-top: 40px;
        padding-bottom: 40px;
      }

      h2 {
        @extend .typography--h2;

        padding-top: 80px;
        padding-bottom: 40px;

        b {
          font-weight: 700 !important;
        }
      }

      h3 {
        @extend .typography--h3;

        b {
          font-weight: 600;
        }


        @include respond-to('fullhd') {
          margin-top: 40px;
          padding-bottom: 40px;
        }
      }

      h4 {
        @extend .typography--h4;

        b {
          font-weight: 600;
        }
      }

      ul {
        padding-top: 30px;
        padding-bottom: 30px;

        li {
          font-size: 15px;
          font-weight: 500;
          line-height: 25px;
          background: url('img/tick.svg') no-repeat 2px 6px transparent;
          list-style-type: none;
          margin: 0;
          padding: 0 0 11px 42px;
          vertical-align: middle;

          &::before {
            padding-right: 8px;
          }

          @include respond-to('fullhd') {
            font-size: 19px;
            line-height: 30px;
          }
        }
      }

      strong,
      b {
        font-weight: 600;
      }

      p {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      img {
        @include respond-to('phone') {
          margin-left: 0;
          width: 100%;
          height: 100%;
        }

        @include respond-to('tablet') {
          margin-left: 0;
          width: 100%;
          height: 100%;
        }
      }

      a {
        color: #000;
      }
    }
  }
}

.other-case-margin-100 {
  margin-top: 100px;
}

.fullpage-image {
  display: grid;

  picture {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__mobile {
    @include respond-to('phone') {
      display: block !important;
    }

    display: none !important;
  }

  &__desktop {
    @include respond-to('phone') {
      display: none !important;
    }
  }

  width: 100%;

  > img {
    width: 100% !important;
    height: 100% !important;
    display: grid;
  }
}

h1.career__left__topic {
  color: #fff;
}

.case-number {
  position: relative;
  width: 100%;

  &.padding {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include respond-to('phone') {
      grid-template-columns: repeat(1, 1fr);
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &__box {
      border-left: 1px solid #fff;
      height: 120px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 40px;

      @include respond-to('phone') {
        margin-top: 25px;
        height: auto;
      }

      &--label {
        font-size: 14px;
        font-family: 'Work Sans', sans-serif;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #fff;
        padding-bottom: 20px;
      }

      &--value {
        font-weight: 400;
        font-family: 'Work Sans', sans-serif;
        font-size: 19px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #fff;
      }
    }
  }
}

.case-studies-item {
  display: inline-block;
  width: calc(50% - 7px);
  padding: 15px;
  background: #1d1927;

  &:first-of-type {
    margin-right: 7px;
  }

  &__title {
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
  }

  &__subtitle {
    color: #979797;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &__number {
    padding-top: 50px;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 1;
    font-size: 40px;
    line-height: 25px;
    letter-spacing: 0.005em;
    color: #efff04;
  }
}

.image-full-page {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90vh;

  img {
    object-fit: cover;
  }
}

.hero-details {
  padding-top: 90px;
  width: 100%;
  position: relative;
  z-index: 1;
  display: grid;
  min-height: 600px;

  @include respond-to('phone') {
    min-height: 100%;
    padding-top: 151px;
    padding-bottom: 50px;
    row-gap: 30px;
    margin-bottom: 0;
  }

  .hero-video {
    width: 100%;
    position: relative;
    padding: 0 104px;

    @include respond-to('phone') {
      height: 50vh !important;
      padding: 0;
      position: relative;
      top: 40px;
      margin-bottom: 80px;
    }

    &__container {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      width: 400px;
      height: auto;
      position: absolute;
      bottom: 42px;
      z-index: 99;

      @include respond-to('phone') {
        margin-top: 40px;
        width: 100% !important;
        height: 100% !important;
      }

    }

    video {
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9 !important;
      object-fit: cover;
      overflow: hidden;
      max-width: calc(100vw - 208px);

      @include respond-to('phone') {
        height: 50vh !important;
        width: 100% !important;
        max-width: 100vw;
      }
    }

    @include respond-to('phone') {
      display: flex;
      flex-direction: column;
    }

    @include respond-to('tablet') {
      width: calc(100%) !important;
    }
  }

  .sticky-banner {
    float: right;
    margin-right: 0;
    position: relative;

    //background-color: $text-primary;
    bottom: 10px;
    right: 0;

    //border: 1px solid $surface-primary;
    color: #fff;
    z-index: 1;

    @include respond-to('phone') {
      position: relative;
      bottom: -10vh;
      left: 16px;
      transform: none;
      width: calc(100% - 32px);
      margin-bottom: 20px;
      z-index: 1000;
      top: 31vh;
    }

    @include respond-to('tablet') {
      width: 250px;
    }

    @include respond-to('desktop') {
      width: 350px;
    }

    @include respond-to('hd') {
      width: 493px;
    }

    .title-description {
      padding: 40px 0;

      //border-bottom: 1px solid $surface-primary;

      @include respond-to('phone') {
        padding: 32px 24px;
        background: #fff;
        color: #0B0A0E;
      }

      @include respond-to('tablet') {
        padding: 10px 5px;
      }

      @include respond-to('desktop') {
        padding: 30px 0;
      }
    }

    .yellow-button-big {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $brand-yellow;
      border: none;

      @include respond-to('phone') {
        padding: 0 24px;
      }

      .btn-text {
        text-align: left;
        padding: 20px;

        @include respond-to('phone') {
          padding: 20px 0;
        }

        @include respond-to('tablet') {
          padding: 24px;
        }
      }

      .btn-img {
        width: 32px;
        padding: 9px;
        margin-right: 20px;

        @include respond-to('phone') {
          margin-right: 0;
        }
      }
    }
  }

  .hero-top-text {
    padding: 72px 104px;
    color: $primary-color;
    display: flex;
    flex-direction: column;
    row-gap: 70px;

    @include respond-to('phone') {
      padding: 0;
      padding-left: 16px;
      padding-right: 16px;
    }


    h1 {
      .highlighted-text {
        background-color: #fff;
        color: $surface-primary;
        font-family: 'Space Grotesk', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
        padding: 0 8px;
        display: inline-block;
        position: relative;
        padding-right: 16px !important;

        &_line {
          display: inline-block;
          background: #fff;
          color: $surface-primary;
          margin-left: -20px;
          position: relative;
          z-index: 2;
          animation: cursor .4s step-end infinite alternate;

          @keyframes cursor {
            50% {
              color: transparent;
            }
          }
        }

        @include respond-to('phone') {
          font-size: 32px;
          line-height: 110%;
        }
      }
    }

    p {
      @include respond-to('phone') {
        width: 100%;
        font-weight: 500;
      }
    }
  }
}

.contactInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__list {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 27px;
    grid-gap: 32px;

    @include respond-to('phone') {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 24px 16px;
    }


    li {
      width: fit-content;
      display: inline-grid;

      @include respond-to('phone') {
        width: auto;
        justify-content: left;
      }

      img {
        height: 32px;

        @include respond-to('phone') {
          width: 100%;
          height: auto;
          max-height: 30px;
          object-fit: contain;
        }
      }
    }
  }
}

@keyframes highlightedTextAnimation {
  0% {
    letter-spacing: 2px;
  }

  20% {
    letter-spacing: 2px;
  }

  80% {
    letter-spacing: 0;
  }

  100% {
    letter-spacing: 0;
  }
}

@keyframes highlightedTextBackgroundAnimation {
  0% {
    width: 0;
    letter-spacing: 2px;
  }

  20% {
    width: 0;
    letter-spacing: 2px;
  }

  80% {
    letter-spacing: 0;
    width: calc(100% - 1px);
  }

  100% {
    letter-spacing: 0;
    width: calc(100% - 1px);
  }
}


.hidden {
  opacity: 0;
}


#jsHighlightedTextTarget {
  background: #fff;
  color: #0b0a0e;
  padding-left: 7px;
  padding-right: 6px;
  margin-right: 5px;

}
