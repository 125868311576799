.number {
  padding-top: 60px;
  padding-bottom: 60px;

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 50px;

    @include respond-to('phone') {
      grid-template-columns: repeat(1, 1fr);
    }

    &__box {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      justify-content: center;
      padding: 20px;
      border: 2px solid #979797;

      &--first {
        background: #efff04;
        border: 2px solid #efff04;
        color: #0b0a0e !important;

        .number__list__box--label,
        .number__list__box--describe,
        .number__list__box--number {
          color: #0b0a0e !important;
        }
      }

      @include respond-to('phone') {
        grid-template-columns: 1fr;
        margin-top: 40px;
        direction: rtl;
      }

      &--number {
        text-align: left;
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 72px;
        letter-spacing: 0.005em;
        line-height: 70px;
        margin-top: 80px;
        color: #efff04;
      }

      &--label {
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.01em;
        color: #f0edeb;

        @include respond-to('phone') {
          text-align: left;
        }
      }

      &--describe {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #f0edeb;

        @include respond-to('phone') {
          text-align: left;
        }
      }
    }
  }
}
