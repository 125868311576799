.section-info {
  color: #21211f;
  padding-top: 75px;
  padding-bottom: 50px;

  @include respond-to('phone') {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  &.extra-padding {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  &.background-black {
    background: #0b0a0e;

    h2,
    p {
      color: #fff;
    }
  }

  h2 {
    font-size: 45px;
    font-weight: 700;
    letter-spacing: -2px;
    line-height: 60px;
    margin-bottom: 18px;

    @include respond-to('phone') {
      font-weight: 700 !important;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.17px;
    line-height: 32px;
    max-width: 700px;
  }
}
