.section-title {
  padding-top: 104px !important;
  padding-bottom: 104px !important;
  background: white;
  display: flex;
  gap: 32px;

  @include respond-to('phone') {
    padding-top: 44px !important;
  }

  &__no-subtitle {
    display: block;
  }

  h2 {
    width: 70%;
  }

  p {
    width: 30%;
  }

  @include respond-to('tablet') {
    flex-direction: column;

    p {
      width: 100%;
    }

    h2 {
      width: 100%;
    }
  }

  @include respond-to('phone') {
    flex-direction: column;

    p {
      width: 100%;
    }

    h3 {
      width: 100%;
    }
  }
}
