.contact-nav {
  background-color: #0b0a0e;
  height: 80px;
  margin: auto;
  display: grid;
  place-content: center center;

  &__href {
    width: 180px;
  }
}

.contact-body {
  background-color: #f0edeb;
  height: calc(100% - 80px);
  width: 100%;
  padding-top: 4%;

  @include respond-to('phone') {
    padding-top: 0;
  }

  .container-contact {
    margin: auto;
    width: 440px;

    @include respond-to('phone') {
      width: 100%;
    }
  }

  .box {
    background-color: #fff;
    padding: 34px 60px;

    @include respond-to('phone') {
      padding: 30px;
    }

    &__success {
      text-align: center;
      padding: 30px;
      width: 100%;
      margin-bottom: 30px;
      border: 1px solid forestgreen;
      color: forestgreen;
      font-weight: 700;
      font-family: 'Space Grotesk', sans-serif;
      font-size: 17px;
    }

    h1 {
      font-family: 'Space Grotesk', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      letter-spacing: -0.05em;
      font-feature-settings: 'dnom' on, 'numr' on, 'sinf' on, 'case' on, 'zero' on, 'ss04' on, 'ss05' on, 'ss03' on,
        'ss01' on, 'salt' on, 'ordn' on;
      color: #0b0a0e;
      margin-bottom: 22px;
    }

    p {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      letter-spacing: -0.05em;
      color: #0b0a0e;
      margin-bottom: 40px;
    }

    .contact__form__container__input,
    .contact__form__container__textarea {
      color: #0b0a0e;

      &::input-placeholder,
      &::-webkit-textarea-placeholder {
        color: #0b0a0e;
      }
    }

    .details-home__body__button {
      color: #fff;
      position: relative !important;
      background-color: #0b0a0e !important;
      height: auto;
      border: none;
      padding: 0;
      align-content: center;
      display: grid;
      cursor: pointer;
    }

    .contact__form__container__label--privacy {
      margin-top: 0;
      color: black;
      font-size: 14px;
    }

    .details-home__body__button--services {
      position: relative;
      margin: auto;
      background: #efff04 !important;
      height: 86px;
      border: none;
      width: 100%;
      margin-top: 15px;
      cursor: pointer;
      margin-bottom: 35px;
    }

    .error {
      border: 1px solid red !important;
    }

    .error_rodo {
      color: red;
    }

    .details-home__body__button__txt {
      align-content: center;
      display: grid;
      border: none;
      color: #13101b;
      padding: 0;
      font-weight: 700;
      font-size: 20px;
      border-right: 2px solid #1a1b1c;
    }

    &__rodo {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.05em;
    }
  }
}
